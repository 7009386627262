/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {VolunteerReportCard} from '../_new/cards/VolunteerReportCard'
import {getAllNotificationListService} from '../../../../app/services/Services'
import {getValidDate} from '../../../../app/helpers/CommonHelpers'
// import {showToast} from '../../../../customModel'

const SendNotificationTable = ({className}) => {
  const [notificationList, setNotificationList] = useState([])
  const [limit, setLimit] = useState(20)
  const [offset, setOffset] = useState(0)
  const [totalCountfromAPI, setTotalCountfromAPI] = useState('')
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [pgNo, setPgNo] = useState(1)

  const data = {
    offset: offset,
    limit: limit,
    kind: 1,
    app_id: '291fbfde-47dd-44e8-b5f3-ab359c72e09a',
  }
  const totalPages = Math.ceil(totalCountfromAPI / 20)
  useEffect(() => {
    getAllNotificationListService(data)
      .then((Response) => {
        console.log('aman', Response)
        setNotificationList(Response?.notifications)
        // console.log('notification  data', Response.total_count)
        setTotalCountfromAPI(Response?.total_count)
      })
      .finally(() => setIsLoading(false))
  }, [offset, page])

  return (
    <>
      {isLoading && (
        <div className='w-25 mt-20 mx-auto'>
          <span className='spinner-border spinner-border-sm align-middle ms-2' />{' '}
        </div>
      )}

      <div className='container mt-20'>
        <div className={`card ${className} shadow mx-auto`}>
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive '>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead className='fw-bold fs-6 text-black text-center'>
                  <th className='w-auto'>S. no</th>
                  <th className='w-auto'>Assembly</th>
                  <th className='w-auto'>User</th>
                  <th className='w-auto'>Title</th>
                  <th className='w-auto'>Message</th>
                  <th className='w-auto'>Date & Time</th>
                </thead>

                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody className='text-center'>
                  {notificationList.map((item, index) => (
                    <tr className='fs-6 fw-bold text-gray-800' key={item?.id}>
                      <td>{page * 10 + index + 1}</td>
                      <td>Naur</td>
                      <td>
                        {item?.filters?.filter((i) => i?.key === 'user_type')?.[0]?.value ?? 'No'}
                      </td>
                      <td>{item?.headings?.en}</td>
                      <td>{item.contents.en}</td>

                      <td>{getValidDate(item?.queued_at * 1000, 'DD/MM/YYYY')}</td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
          <div className='d-flex justify-content-end align-items-center gap-10 me-10 my-10'>
            {offset !== 0 && (
              <div
                className='btn btn-dark'
                onClick={() => {
                  setOffset(offset - 20)
                  setPage(page - 2)
                  setPgNo(pgNo - 1)
                }}
              >
                Previous
              </div>
            )}
            <div className='fw-bold text-muted'>
              {pgNo} /{totalPages}
            </div>
            {totalPages > pgNo && (
              <div
                className='btn btn-success'
                onClick={() => {
                  setOffset(offset + 20)
                  setPage(page + 2)
                  setPgNo(pgNo + 1)
                }}
              >
                Next
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {SendNotificationTable}

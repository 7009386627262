import withoutAuthAxios from './withoutAuthAxios'
import EndPoints from './EndPoints'
import {jsonToFormData} from '../helpers/CommonHelpers'
import authAxios from './authAxios'
import normalAxios from './normalAxios'

// Login
export const isPhoneExistOrNotService = (phone) =>
  withoutAuthAxios.get(EndPoints.isPhoneExistOrNot, {
    params: {
      phone,
      buildNo: 0,
    },
  })

// AdminDashboard
export const dashboardDataService = (data) =>
  authAxios.get(EndPoints.dashboardData, {
    params: data,
  })

//Get total Counts and Total Created by me for admin dashboard,
export const getBoothVolCountbyAcNoService = (AC_NO) =>
  authAxios.get(EndPoints.getBoothVolCountbyAcNo, {
    params: {AC_NO},
  })

// Post booth count from admin dashboard
export const createBoothCountbyAcNoService = (AC_NO, data) =>
  authAxios.post(EndPoints.createBoothCountbyAcNo, data, {
    params: {AC_NO},
  })
//  for Volunteers Reports Page
export const getVoluteerCountService = (data) =>
  authAxios.get(EndPoints.getVoluteerCount, {
    params: data,
  })

// Get notification users list
export const getAllNotificationListService = (data) =>
  normalAxios.get(EndPoints.getAllNotificationList, {
    params: data,
    headers: {
      Authorization: 'Basic OWI1OTQ0ZjMtNDBiZi00YzJlLTk4ODQtNTc2NTQwZDBhMWJh',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

//Send web push notification
export const createWebPushNotificationService = (title, message, role_type, AC_NO) => {
  const OneSignalId = '291fbfde-47dd-44e8-b5f3-ab359c72e09a'
  const data = {
    app_id: OneSignalId,
    channel_for_external_user_ids: 'push',
    // data: {
    //   customData: 'bar',
    // },
    headings: {
      en: title,
    },
    contents: {
      en: message,
    },
    // big_picture:
    //   'https://i.picsum.photos/id/116/200/300.jpg?hmac=uPAnc-n8DQQ64MK4BNdh5H0jZftgEJ1sWpFPDBHni5o',
  }

  if (role_type === 'All') {
    data.included_segments = ['Subscribed Users']
  } else {
    data.filters = [
      {
        field: 'tag',
        key: 'user_type',
        relation: '=',
        value: role_type,
      },
      {
        key: 'assemblyId',
        field: 'tag',
        value: AC_NO,
        relation: 'exists',
      },
    ]
  }

  normalAxios.post(EndPoints.sendWebPushNotification, data, {
    headers: {
      Authorization: `Basic OWI1OTQ0ZjMtNDBiZi00YzJlLTk4ODQtNTc2NTQwZDBhMWJh`,
      'Content-Type': 'application/json',
    },
  })
}

// Get assembly-reports data
export const getReportsDataService = (data) =>
  authAxios.get(EndPoints.getReportsData, {
    params: data,
  })

//get QandA for filter options
export const getQandAforfilterService = () => authAxios.get(EndPoints.getQandAforfilter)

//Get api for booth volunter dashboard
export const getBoothVolDashboardDataSevice = (data) =>
  authAxios.get(EndPoints.getBoothVolDashboardData, {
    params: data,
  })

//Get user list for activity progress
export const getActivityUserListService = (data) =>
  authAxios.get(EndPoints.getActivityUserList, {params: data})

//Get user list count for acitivity progress
export const getActivityUserListCountService = (getCountData) =>
  authAxios.get(EndPoints.getActivityUserListCount, {params: getCountData})

export const getUserMapService = (data) => authAxios.get(EndPoints.getUserMap, {params: data})

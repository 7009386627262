/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {VolunteerReportCard} from '../_new/cards/VolunteerReportCard'
// import {showToast} from '../../../../customModel'

const MyReportVolunteersTable = ({className}) => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [total, setTotal] = useState([])

  const UserLogins = JSON.parse(localStorage.getItem('userLogins'))
  // console.log(UserLogins.ACCESS[0].PART)
  const UserAssembly = UserLogins.ACCESS[0]

  // useEffect(() => {
  //   // api()
  //   showToast.success('WELCOME BOOTH VOLUNTEERS')
  // }, [])

  // const renderPollingStations = (item, index) => {
  //   return <PollingStationCard child={item} childIndex={index} />
  // }
  return (
    <div className='container'>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          color: '#0a8202 ',
          fontSize: '20px',
        }}
      >
        My Reports
      </h2>
      <div className={`card ${className} shadow  w-md-75 mx-auto`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                {/* <tr className='fw-bold text-muted'>
                  <th className='w-auto'>Name</th>
                  <th className='w-auto'>Assigned to me</th>

                  <th className='w-auto'>Actions</th>
                </tr> */}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>
                    <p
                      className='text-dark fw-bold  d-block mb-1 fs-6 py-3'
                      style={{textAlign: 'left', cursor: 'pointer'}}
                      // onClick={() => navigate('/user-card', {state: child})}
                    >
                      <span className='fs-4' style={{color: '#0a8202 '}}>
                        {' '}
                        Total electoral records:
                      </span>
                      <br />
                      Count to come from api
                    </p>
                    <hr />
                    <p
                      className='text-dark fw-bold  d-block mb-1 fs-6 py-3'
                      style={{textAlign: 'left', cursor: 'pointer'}}
                      // onClick={() => navigate('/user-card', {state: child})}
                    >
                      <span className='fs-4' style={{color: '#0a8202 '}}>
                        Total updated
                      </span>
                      <br />
                      Count to come from api
                      <br />
                      Total updated by me: count
                    </p>
                  </td>

                  {/* <td>
                    <p
                      className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                      style={{textAlign: 'left', cursor: 'pointer'}}
                      // onClick={() => navigate('/user-card', {state: child})}
                    >
                      Assembly: {UserAssembly.AC_NO} - {UserAssembly.ASSEMBLY_NAME_EN} |
                      {UserAssembly.ASSEMBLY_NAME_HINDI}
                    </p>
                  </td> */}
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='row g-10 g-xl-10 mb-5 mb-xl-10 mt-8 w-md-75 mx-auto'>
        <div className='w-md-50 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <VolunteerReportCard
          // type='user'
          // totalToBeCreated={totalToBeCreated}
          // reportCount={reportCount}
          // className='h-md-70 '
          // description='Active Projects'
          // color='white'
          // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
      </div>
    </div>
  )
}

// const PollingStationCard = ({child, childIndex}) => {
//   const [isReadMore, setIsReadMore] = useState(false)
//   const navigate = useNavigate()

//   const currentData = useMemo(
//     () => (!isReadMore ? child.PSBUILDING.slice(0, 2) : child.PSBUILDING),
//     [isReadMore, child]
//   )

//   return (
//     <div>
//       {childIndex > 0 && <hr />}
//       <p
//         className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
//         style={{textAlign: 'left', cursor: 'pointer'}}
//         onClick={() => navigate('/user-card', {state: child})}
//       >
//         Assembly: {UserAssembly.AC_NO} - {UserAssembly.ASSEMBLY_NAME_EN} |{' '}
//         {UserAssembly.ASSEMBLY_NAME_HINDI}
//       </p>
//       <hr />
//       {/* {currentData.map((grandChild, grandChildIndex) => (
//         <>
//           <p
//             style={{textAlign: 'left'}}
//           >{`${grandChild.PSBUILDING_ID} - ${grandChild.PSBUILDING_NAME_EN} | ${grandChild.PSBUILDING_NAME_V1}`}</p>
//           {grandChildIndex !== currentData?.length - 1 && <hr />}
//         </>
//       ))}
//       {child.PSBUILDING?.length > 2 && (
//         <strong
//           style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer', color: '#0a8202 '}}
//           onClick={() => setIsReadMore(!isReadMore)}
//         >
//           {isReadMore ? 'Read Less...' : `Read More(${child.PSBUILDING.length - 2})`}
//         </strong>
//       )} */}
//     </div>
//   )
// }
export {MyReportVolunteersTable}

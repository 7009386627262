import React from 'react'
import { BoothVolenteersTable } from '../../../_metronic/partials/widgets/tables/BoothVolenteersTable';
import { CommitteeMemberTable } from '../../../_metronic/partials/widgets/tables/CommitteeMemberTable';
import { MyReportVolunteersTable } from '../../../_metronic/partials/widgets/tables/MyReportVolunteersTable';
// import { BoothInArea } from '../../modules/widgets/components/BoothInArea';
import {AdminDashboard} from './adminDashboard/AdminDashboard'
import { InchargeDashboard } from './inchargeDashboard/InchargeDashboard';
import { VolunteerDashboard } from './volunteerDashboard/VolunteerDashboard';

const DashboardWrapper = () => {
  let UserLogins = JSON.parse(localStorage.getItem('userLogins'))
  console.log('rk', UserLogins);
  // console.log(UserLogins.ROLE.ROLE_ID);
  if (UserLogins.ROLE.ROLE_ID ==3) return <AdminDashboard />
  if (UserLogins.ROLE.ROLE_ID ==2) return <VolunteerDashboard />
  if (UserLogins.ROLE.ROLE_ID ==1) return <CommitteeMemberTable />
  if (UserLogins.ROLE.ROLE_ID ==5) return <InchargeDashboard />

}

export {DashboardWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

const CustomPieChart= ({className, title, data, horizontal}) => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, horizontal))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className} shadow`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>More than 900+ new members</span>
        </h3>

        {/* begin::Toolbar */}
        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_2_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_2_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_2_week_btn'
          >
            Week
          </a>
        </div> */}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {CustomPieChart}

function getChartOptions(height, data, horizontal = false) {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-warning')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  const values = data?.map((item) => item?.value)
  const colors = data?.map((item) => item?.color)
  const labels = data?.map((item) => item?.label)

  
  return {
    series: [
      {
        // name: 'Problem Facing',
        data: values,
        color: colors?.[0],
      },
    ],
    xaxis: {
      categories: labels,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      // toolbar: {
      //   // show: false,
      // },
    },
    plotOptions: {
      bar: {
        horizontal: horizontal,
        columnWidth: '15%',
        borderRadius: 5,
        barHeight: '45',
      },
      
    },
    // legend: {
    //   show: false,
    // },
    dataLabels: {
      enabled: false,
    },
    // stroke: {
    //   show: true,
    //   width: 1,
    //   colors: ['transparent'],
    // },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    // tooltip: {
    //   style: {
    //     fontSize: '12px',
    //   },
    //   y: {
    //     formatter: function (val) {
    //       return '$' + val + ' thousands'
    //     },
    //   },
    // },
    // colors: [baseColor, secondaryColor],
    // grid: {
    //   borderColor: borderColor,
    //   strokeDashArray: 4,
    //   yaxis: {
    //     lines: {
    //       show: true,
    //     },
    //   },
    // },
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from '../../../../app/fetchApi/Api'
// import axios from 'axios'
import React, {useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

const UsersTable = ({className}) => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [total, setTotal] = useState([])

  //Locatiojn
  const Location = useLocation()
  const user = Location.state

  const api = async () => {
    let UserLogins = JSON.parse(localStorage.getItem('userLogins'))
    await axios
      .get(
        `/admin/getUsers?userId=${UserLogins?.USER_ID}&limit=1500&offset=0&ROLE_ID=${UserLogins?.ROLE?.ROLE_ID}&AC_NO=${user.AC_NO}`
      )
      .then((response) => {
        setData(response.data.data)
        response.data.data.map((i) => {
          axios
            .get(`/get/allBVbyCM?userId=${i.USER_ID}`)
            .then((data) => {
              setTotal((oldArray) => [...oldArray, data.data])
            })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  // console.log('total------------', total)
  useEffect(() => {
    api()
  }, [])

  const renderPollingStations = (item, index) => {
    return <PollingStationCard child={item} childIndex={index} />
  }
  return (
    <div className='container'>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          color: '#0a8202 ',
          fontSize: '20px',
        }}
      >
        List of users
      </h2>
      <div className={`card ${className} shadow  w-md-75 mx-auto`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-auto'>Name</th>
                  <th className='w-auto'>Polling Stataion</th>

                  {/* <th className='w-auto'>Actions</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.USER_ID}>
                    <td
                      className='d-flex-col align-items-start'
                      onClick={() => navigate('/user-card', {state: {...item, AC_NO: user.AC_NO}})}
                    >
                      <p
                        className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                        style={{cursor: 'pointer'}}
                      >
                        {item.NAME}
                      </p>

                      {total.map((i, ind) => (
                        <>
                          {index == ind ? (
                            <p
                              style={{
                                background: 'green',
                                borderRadius: '60px',
                                color: 'white',
                                width: '35px',
                                height: '25px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {i.count}
                            </p>
                          ) : (
                            <p></p>
                          )}
                        </>
                      ))}
                    </td>
                    <td>{item.ACCESS.map(renderPollingStations)}</td>
                    {/* <td>
                 
                </td> */}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

const PollingStationCard = ({child, childIndex}) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const navigate = useNavigate()

  const currentData = useMemo(
    () => (!isReadMore ? child.PSBUILDING.slice(0, 2) : child.PSBUILDING),
    [isReadMore, child]
  )
  return (
    <div>
      {childIndex > 0 && <hr />}
      <p
        className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
        style={{textAlign: 'left', cursor: 'pointer'}}
        onClick={() => navigate('/user-card', {state: child})}
      >
        Assembly: {child.AC_NO} - {child.ASSEMBLY_NAME_EN} | {child.ASSEMBLY_NAME_HINDI}
      </p>
      <hr />
      {currentData.map((grandChild, grandChildIndex) => (
        <>
          <p
            style={{textAlign: 'left'}}
          >{`${grandChild.PSBUILDING_ID} - ${grandChild.PSBUILDING_NAME_EN} | ${grandChild.PSBUILDING_NAME_V1}`}</p>
          {grandChildIndex !== currentData?.length - 1 && <hr />}
        </>
      ))}
      {child.PSBUILDING?.length > 2 && (
        <strong
          style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer', color: '#0a8202 '}}
          onClick={() => setIsReadMore(!isReadMore)}
        >
          {isReadMore ? 'Read Less...' : `Read More(${child.PSBUILDING.length - 2})`}
        </strong>
      )}
    </div>
  )
}
export {UsersTable}

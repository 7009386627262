import React from "react"
import {useNavigate} from "react-router-dom"
const CardsWidget21 = ({
    type,
    className, 
    reportCount,
    totalCreatedByMe,
    totalToBeCreated,
    color, 
    img, 
}) => {
  let commiteeMemberRatio=0
  if(reportCount>0)
  {
   commiteeMemberRatio = (totalToBeCreated * 100 / (reportCount * 100))
  }
  console.log("-------------------", reportCount,
 
  totalToBeCreated,)
  var navigate = useNavigate()
  const handle = () => {
    navigate('/user-list')
  }
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end shadow ${className}`}
      style={{
        backgroundColor: color,
        height: '200px',
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='d-flex justify-content-between fw-bold text-black fs-20 opacity-75 w-100 mt-5 mb-2'>
          <span className='text-black fw-bold fs-6' style={{fontWeight: 'bold'}}>
            {' '}
            <b>This Week</b>
          </span>{' '}
          <span>
           {type != 'user' && reportCount > 0 && <button
              onClick={() => handle()}
              className='btn-lg fs-md-8'
              style={{
                background: 'rgba(54, 153, 255, 0.1)',
                border: ' 1px solid rgba(54, 153, 255, 0.1)',
                color: '#3699FF',
                fontSize: '12px',
                padding: '6px',
                borderRadius: '7px',
              }}
            >
              List of Users
            </button>}
          </span>
          <b style={{color: '#F35063'}}>{commiteeMemberRatio}% of Target {totalToBeCreated * 10}</b>
        </div>
      </div>
      <div className='card-body d-flex align-items-start pt-0 h-50'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div style={{width: '90%', height: '18px'}} className='mx-3 w-100 bg-gray-200  rounded'>
            <div
              className='bg-danger rounded'
              role='progressbar'
              style={{width: `${commiteeMemberRatio}%`, height: '18px', background: 'red'}}
              aria-valuenow={reportCount * 10 - totalCreatedByMe}
              aria-valuemin={0}
              aria-valuemax={reportCount * 10}
            ></div>
            <div className='d-flex justify-content-between fw-bold fs-20 text-gray opacity-75 w-100 mt-auto mb-2'>
              <span>0</span>
              <span>
                <span>Total to be created &nbsp;{totalToBeCreated * 10}</span>
              </span>
            </div>
            <div style={{marginTop: '10px'}} className='d-flex flex-row-fluid'>
              <div
                style={{marginTop: '7px', width: '19px', height: '6px'}}
                className='bullet  rounded-2 bg-danger me-1'
              ></div>
              <div style={{color: 'grey', fontSize: '13px', padding: '0px'}}>
              Total Booth Volunteers : {reportCount == 0 ? '0': reportCount}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
export {CardsWidget21}

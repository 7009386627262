import React from 'react'
import {useNavigate} from 'react-router-dom'
import {GrLocation} from 'react-icons/gr'
const ActivityProgress = ({
  type,
  className,
  totalToBeCreated,
  totalCreatedByMe,
  color,
  AC_NO,
  img,
  allVoters,
  votersCovered,
  votersRemaining,
}) => {
  let commiteeMemberRatio = 0
  if (allVoters > 0) {
    commiteeMemberRatio = (votersCovered * 100) / allVoters
  }

  var navigate = useNavigate()
  const handle = () => {
    navigate('/user-table', {state: {AC_NO}})
  }
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end shadow ${className}`}
      style={{
        backgroundColor: color,
        height: '300px',
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='d-flex flex-column justify-content-between   text-black fs-20 w-100 mt-5 mb-2'>
          <div className='d-flex justify-content-between'>
            <div className='text-black fw-bold fs-5 text-gray-800' style={{fontWeight: 'bold'}}>
              Activity Progress
            </div>
            <div class='form-check form-switch'>
              <input class='form-check-input' type='checkbox' id='flexSwitchCheckDefault' />
              <div className='fw-bold fs-7 text-gray-700'>Switch to house report</div>
            </div>
          </div>
          <div
            style={{width: '90%', height: '18px', backgroundColor: '#F64E60'}}
            className='mx-3 w-100  rounded-pill my-15'
          >
            <div
              className='rounded-pill'
              role='progressbar'
              style={{width: `${commiteeMemberRatio}%`, height: '18px', backgroundColor: '#00A3FF'}}
              //   aria-valuenow={votersCovered*10}
              //   aria-valuemin={0}
              //   aria-valuemax={votersRemaining * 10}
            ></div>
            <div className='d-flex justify-content-between fw-bold text-gray-600'>
              <p>0</p> <p> Total Voters {allVoters}</p>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex fw-bold text-gray-600'>
              <div className='d-flex flex-column gap-2'>
                <div
                  className='bullet  rounded-pill-2 me-1 py-2 px-4'
                  style={{backgroundColor: '#00A3FF'}}
                ></div>
                <div className='text-center'>{votersCovered}</div>
              </div>
              Voters Covered
            </div>
            <div className='d-flex fw-bold text-gray-600'>
              <div className='d-flex flex-column gap-2'>
                <div
                  className='bullet  rounded-pill-2 me-1 py-2 px-4'
                  style={{backgroundColor: '#F64E60'}}
                ></div>
                <div className='text-center cursor:pointer'>{votersRemaining}</div>
              </div>
              Voters Remaining
            </div>
            <div className='d-flex gap-5 fs-sm-7 fs-8'>
              <div
                className='p-2 rounded cursor-pointer fw-bold'
                style={{backgroundColor: 'rgba(68, 195, 149, 0.1)', color: '#44C395'}}
                onClick={() => navigate('/user-location', {state: {AC_NO}})}
              >
                <GrLocation className='text-success' /> Show on map
              </div>
              {/* User List */}
              <div
                className='p-2 rounded cursor-pointer fw-bold'
                style={{backgroundColor: 'rgba(54, 153, 255, 0.1)', color: '#3699FF'}}
                onClick={() => navigate('/user-list-activity', {state: {AC_NO}})}
              >
                User List
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {ActivityProgress}

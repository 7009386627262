import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {BoothInArea} from '../modules/widgets/components/BoothInArea'
import ListofUsers from '../modules/widgets/components/ListofUsers'
import UserCard from '../../_metronic/partials/widgets/_new/cards/UserCard'
import {UserBoothDetails} from '../modules/widgets/components/UserBoothDetails'
import {Boothdetails} from '../modules/widgets/components/Boothdetails'
import {MyReportVolunteersTable} from '../../_metronic/partials/widgets/tables/MyReportVolunteersTable'
import {BoothVolenteersTable} from '../../_metronic/partials/widgets/tables/BoothVolenteersTable'
import {UsersTable} from '../../_metronic/partials/widgets'
import AssemblyReportTable from '../../_metronic/partials/widgets/tables/AssemblyReportTable'
import {BoothStatusPage} from '../modules/widgets/components/BoothStatusPage'
import SendNotification from '../pages/sendNotification/SendNotification'
import ShowUseronMap from '../map/ShowUseronMap'
import {ActivityUserList} from '../../_metronic/partials/widgets/tables/ActivityUserList'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  let UserLogins = JSON.parse(localStorage.getItem('userLogins'))

  return (
    <Routes>
      {UserLogins && (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          {UserLogins.ROLE.ROLE_ID == 3 && (
            <>
              <Route path='auth/*' element={<Navigate to='/dashboard' />} />
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='builder' element={<BuilderPageWrapper />} />
              <Route path='menu-test' element={<MenuTestPage />} />
              <Route path='booth-in-area' element={<BoothInArea />} />
              <Route path='user-list' element={<ListofUsers />} />
              <Route path='user-card' element={<UserCard />} />
              <Route path='user-table' element={<UsersTable />} />
              <Route path='booth-details' element={<Boothdetails />} />
              <Route path='user-booth-details' element={<UserBoothDetails />} />
              <Route path='volunteers-reports' element={<MyReportVolunteersTable />} />
              <Route path='booth-volunteers-table' element={<BoothVolenteersTable />} />
              <Route path='assembly-report' element={<AssemblyReportTable />} />
              <Route path='booth-status-page' element={<BoothStatusPage />} />
              <Route path='send-notification' element={<SendNotification />} />
              <Route path='/user-location' element={<ShowUseronMap />} />
              <Route path='/user-list-activity' element={<ActivityUserList />} />
            </>
          )}
          {UserLogins.ROLE.ROLE_ID == 5 && (
            <>
              <Route path='auth/*' element={<Navigate to='/dashboard' />} />
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='builder' element={<BuilderPageWrapper />} />
              <Route path='menu-test' element={<MenuTestPage />} />
              <Route path='booth-in-area' element={<BoothInArea />} />
              <Route path='user-list' element={<ListofUsers />} />
              <Route path='user-card' element={<UserCard />} />
              <Route path='user-table' element={<UsersTable />} />
              <Route path='booth-details' element={<Boothdetails />} />
              <Route path='user-booth-details' element={<UserBoothDetails />} />
              <Route path='volunteers-reports' element={<MyReportVolunteersTable />} />
              <Route path='booth-volunteers-table' element={<BoothVolenteersTable />} />
              <Route path='assembly-report' element={<AssemblyReportTable />} />
              <Route path='booth-status-page' element={<BoothStatusPage />} />
              <Route path='send-notification' element={<SendNotification />} />
              <Route path='/user-location' element={<ShowUseronMap />} />
              <Route path='/user-list-activity' element={<ActivityUserList />} />
            </>
          )}
          {UserLogins.ROLE.ROLE_ID == 2 && (
            <>
              <Route path='auth/*' element={<Navigate to='/user-card' />} />
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='builder' element={<BuilderPageWrapper />} />
              <Route path='menu-test' element={<MenuTestPage />} />
              <Route path='booth-in-area' element={<BoothInArea />} />
              <Route path='user-list' element={<ListofUsers />} />
              <Route path='user-card' element={<UserCard />} />
              <Route path='user-table' element={<UsersTable />} />
              <Route path='booth-details' element={<Boothdetails />} />
              <Route path='user-booth-details' element={<UserBoothDetails />} />
              <Route path='volunteers-reports' element={<MyReportVolunteersTable />} />
              <Route path='booth-volunteers-table' element={<BoothVolenteersTable />} />
              <Route path='assembly-report' element={<AssemblyReportTable />} />
              <Route path='booth-status-page' element={<BoothStatusPage />} />
              <Route path='send-notification' element={<SendNotification />} />
              <Route path='/user-location' element={<ShowUseronMap />} />
              <Route path='/user-list-activity' element={<ActivityUserList />} />
            </>
          )}
          {/* Pages */}

          {/* Lazy Modules */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      )}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

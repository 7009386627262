/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
  description: string
  icon: string
  stats: number
  labelColor: string
  textColor: string
  color: string
  count: number
  count2: number
  text: string
  text2: string
  style: object
}

const items: Array<{
  name: string
  initials?: string
  src?: string
  state?: string
}> = [
  {name: 'Alan Warden', initials: 'A', state: 'warning'},
  {name: 'Michael Eberon', src: toAbsoluteUrl('/media/avatars/300-11.jpg')},
  {name: 'Susan Redwood', initials: 'S', state: 'primary'},
  {name: 'Melody Macy', src: toAbsoluteUrl('/media/avatars/300-2.jpg')},
  {name: 'Perry Matthew', initials: 'P', state: 'danger'},
  {name: 'Barry Walter', src: toAbsoluteUrl('/media/avatars/300-12.jpg')},
]

const CardsWidget7 = ({
  className,
  description,
  color,
  icon,
  stats,
  labelColor,
  textColor,
  text,
  count,
  count2,
  style,
  text2,
}: Props) => (
  <>
    {text2? (
      <div
        className={`d-flex card card-flush ${className} shadow`}
        style={{
          backgroundColor: color,
          padding: 15,
          ...style,
        }}
      >
        {/* <img{icon}></img> */}
        <div className='d-flex justify-content-around'>
        <div className='ms-3'>
          <img src={icon} width={30} />
          <p
            className='text-white fw-bold fs-1 my-2'
            style={{
              fontSize: '25px',
            }}
          >
            {count}
          </p>
          <p className='text-white fw-bold fs-7 my-0'>{text}</p>
        </div>
            <div className='border-start' ></div>
        <div className='ms-3'>
          <img src={icon} width={30} />
          <p
            className='text-white fw-bold fs-1 my-2'
            style={{
              fontSize: '25px',
            }}
          >
            {count2}
          </p>
          <p className='text-white fw-bold fs-7 my-0'>{text2}</p>
        </div>
      </div>
      </div>
    ) : (
      <div
        className={`d-flex card card-flush ${className} shadow`}
        style={{
          backgroundColor: color,
          padding: 15,
          ...style,
        }}
      >
        {/* <img{icon}></img> */}
        <div className='ms-3'>
          <img src={icon} width={30} />
          <p
            className='text-white fw-bold fs-1 my-2'
            style={{
              fontSize: '25px',
            }}
          >
            {count}
          </p>
          <p className='text-white fw-bold fs-7 my-0'>{text}</p>
        </div>
      </div>
    )}
  </>
)
export {CardsWidget7}

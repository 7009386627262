import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react'
import {getUserMapService} from '../services/Services'
import {useLocation} from 'react-router-dom'

const AnyReactComponent = ({text}) => <div className='fw-bold text-gray-600'>{text}</div>

const ShowUseronMap = () => {
  const Location = useLocation()
  const user = Location.state
  const [userMapData, setUserMapData] = useState([])

  useEffect(() => {
    const data = {AC_NO: user.AC_NO}
    getUserMapService(data).then((Response) => {
      console.log('AS', Response.data.data)
      setUserMapData(Response.data.data)
    })
  }, [])

  const defaultProps = {
    center: {
      lat: 22.716005969397475,
      lng: 75.85223263637845,
    },
    zoom: 11,
  }
  return (
    <div style={{height: '91vh', width: '100%'}}>
      <GoogleMapReact
        bootstrapURLKeys={{key: ''}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {userMapData.map((item) => (
          <AnyReactComponent lat={item.Lat} lng={item.Lng} text={item.VName} />
        ))}
      </GoogleMapReact>
    </div>
  )
}

export default ShowUseronMap

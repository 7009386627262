import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ToastContainer} from 'react-toastify'
import OneSignal from 'react-onesignal'
import { logoutAllTabs } from '../_metronic/layout/components/header/Navbar'

const App = () => {
  const [initialized, setInitialized] = useState(false)

  OneSignal.init({appId: '291fbfde-47dd-44e8-b5f3-ab359c72e09a'}).then(() => {
    setInitialized(true)
    OneSignal.showSlidedownPrompt().then(() => {
      // alert(initialized)
    })
  })
  // alert('workinh')

  useEffect(() => {
   logoutAllTabs()
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer />
    </Suspense>
  )
}

export {App}

import React, {useEffect, useState, useMemo} from 'react'
import allBooths from './../../../../JSON/booth.json'
import allPStations from './../../../../JSON/pstation.json'
// import AllBoth from "./../../../../JSON/booth.json"
// import UserBoothTable from '../../../../_metronic/partials/widgets/tables/userBoothTable'
import {TablesWidget13} from '../../../../_metronic/partials/widgets'
import UserBoothTable from '../../../../_metronic/partials/widgets/tables/UserBoothTable'
import {useLocation} from 'react-router-dom'

const UserBoothDetails = () => {
  const Location = useLocation()
  const user = Location.state
  const [currentBoothTab, setCurrentBoothTab] = useState('completed')

  const boothTableData = useMemo(() => {
    if (user.type === 'allBooths') return user?.boothData
    if (currentBoothTab === 'completed') return user?.completedData
    if (currentBoothTab === 'touched') return user?.touchData
    if (currentBoothTab === 'untouched') return user?.untouchData
  }, [currentBoothTab, user?.boothData, user?.completedData, user?.touchData, user.type, user?.untouchData])

  return (
    <>
      <div className='container'>
        <h2
          style={{
            display: 'flex',
            color: '#0a8202 ',
            justifyContent: 'center',
            marginBottom: '20px',
            fontSize: '20px',
          }}
        >
          Booth in Area ({user?.totalCount})
        </h2>
        {user.type != 'allBooths' && (
          <div className='row flex-center w-100 text-center mb-5'>
            <div class='row flex-center w-50  text-center mb-5'>
              <div class='col mt-2 '>
                <button
                  className='btn-lg font-size-sm'
                  style={{
                    background:
                      currentBoothTab === 'completed' ? '#0a8202' : 'rgba(54, 153, 255, 0.1)',
                    color: currentBoothTab === 'completed' ? 'white' : 'black',
                    borderRadius: '7px',
                    border: ' 0px solid #0a8202 ',
                    fontSize: '12px',
                    padding: '6px',
                  }}
                  onClick={() => {
                    setCurrentBoothTab('completed')
                  }}
                >
                  {/* Completed ({user.completedData.length}) */}
                </button>
              </div>
              <div class='col  mt-2'>
                <button
                  onClick={() => {
                    setCurrentBoothTab('touched')
                  }}
                  className='btn-lg font-size-sm'
                  style={{
                    border: ' 0px solid rgba(54, 153, 255, 0.1)',
                    background:
                      currentBoothTab === 'touched' ? '#0a8202' : 'rgba(54, 153, 255, 0.1)',
                    color: currentBoothTab === 'touched' ? 'white' : 'black',
                    fontSize: '12px',
                    borderRadius: '7px',
                    // width: '100px',
                    padding: '6px',
                  }}
                >
                  Touched ({user.touchData.length})
                </button>
              </div>
              <div class='col  mt-2'>
                <button
                  onClick={() => {
                    setCurrentBoothTab('untouched')
                  }}
                  className='btn-lg font-size-sm'
                  style={{
                    border: ' 0px solid rgba(54, 153, 255, 0.1)',
                    borderRadius: '7px',
                    background:
                      currentBoothTab === 'untouched' ? '#0a8202' : 'rgba(54, 153, 255, 0.1)',
                    color: currentBoothTab === 'untouched' ? 'white' : 'black',
                    fontSize: '12px',
                    padding: '6px',
                  }}
                >
                  Untouched({user.untouchData?.length})
                </button>
              </div>
            </div>
          </div>
        )}
        <UserBoothTable className='mb-5 mb-xl-8' boothTableData={boothTableData} AC_NO={user?.AC_NO}/>
      </div>
    </>
  )
}
export {UserBoothDetails}

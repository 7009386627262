import {BsImageFill} from 'react-icons/bs'
import {FaCloudUploadAlt} from 'react-icons/fa'
import {SendNotificationTable} from '../../../_metronic/partials/widgets/tables/SendNotificationTable'
import imageIcon from '../../Icons/imageIcon.png'
import assembly from '../../../../src/JSON/assembly.json'
import {useEffect, useState} from 'react'
import {createWebPushNotificationService} from '../../services/Services'
import {showToast} from '../../../customModel'

const SendNotification = () => {
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [userType, setUserType] = useState('All')
  const assemblyNames = assembly.data.rows
  const [AC_NO, setACNo] = useState(assemblyNames?.[0]?.AC_NO)

  const onChangeUserType = (e) => {
    setUserType(e.target.value)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    createWebPushNotificationService(title, message, userType, AC_NO)
    showToast.success('Notification sent')
  }

  // useEffect(() => {
  //   console.log('sendnoti',AC_NO)
  // }, [AC_NO])

  return (
    <div className='container mt-20'>
      <form onSubmit={handleSubmit}>
        <div className='d-sm-flex'>
          <div className='w-sm-50 mx-sm-0 mx-auto'>
            {/* Assembly */}
            <div className='d-flex align-items-center mb-10'>
              <div className='fw-bold w-25'>Assembly</div>
              <select
                className='form-select p-2 cursor-pointer w-25'
                aria-label='Select example'
                onChange={(e) => setACNo(e.target.value)}
                value={AC_NO}
              >
                {assemblyNames.map((item) => {
                  // console.log('assembly name',assemblyNames);
                  return (
                    <option key={item.AC_NO} value={item?.AC_NO}>
                      {item.NAME_EN}
                    </option>
                  )
                })}
              </select>
            </div>

            {/* User */}
            <div className='d-flex align-items-center mb-10'>
              <div className='fw-bold w-25'>User type</div>
              <select
                onChange={onChangeUserType}
                value={userType}
                className='form-select p-2 cursor-pointer w-25'
                aria-label='Select example'
              >
                <option value='All'>All</option>
                <option value='Assembly_Incharge'>Assembly Incharge</option>
                <option value='Booth_Member'>Booth Member</option>
                <option value='Admin'>Admin</option>
              </select>
            </div>

            {/* Tite */}
            <div className='d-flex align-items-center mb-10'>
              <div className='fw-bold w-25'>
                Title<span className='text-danger'>*</span>
              </div>
              <input
                type='text'
                className='form-control form-control-white w-50'
                required
                placeholder='title'
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            {/* Message */}
            <div className='d-flex  align-items-center mb-10'>
              <div className='fw-bold w-25'>
                Message<span className='text-danger'>*</span>
              </div>
              <input
                type='text'
                className='form-control form-control-white w-50'
                placeholder='message'
                required
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            {/* button */}
            <div className='w-75 text-center'>
              <button
                type='submit'
                className='btn btn-dark text-white py-2 px-10'
                style={{backgroundColor: '#0a8202'}}
              >
                Send
              </button>
            </div>
          </div>

          <div className='d-flex align-items-center border border-secondary border-2 rounded bg-white w-sm-25 w-75 shadow mx-sm-0 mx-auto mt-sm-0 mt-10'>
            <div className='mx-auto py-5'>
              <div>
                <div className='mb-5 w-50 mx-auto '>
                  <img src={imageIcon} alt='image' width={60} />
                </div>
                <div className=''>
                  <label
                    className='btn fs-8 py-2 px-4 text-white'
                    style={{backgroundColor: '#0a8202'}}
                  >
                    <FaCloudUploadAlt className='me-2' />
                    <input type='file' style={{display: 'none'}} id='file' name='file' />
                    Upload Image
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
        <SendNotificationTable />
      </div>
    </div>
  )
}

export default SendNotification

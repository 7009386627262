/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import axios from '../../../fetchApi/Api'
import {FC, useState, useEffect} from 'react'
import allBooths from '../../../../JSON/booth.json'
import allPStations from '../../../../JSON/pstation.json'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageTitle, useLayout} from '../../../../_metronic/layout/core'
import AddUser from '../../../Icons/AddUser.png'
import AddCall from '../../../Icons/AddCall.png'
import Group from '../../../Icons/Group.png'
import assembly from '../../../../JSON/assembly.json'
import {FiFilter} from 'react-icons/fi'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget2,
  ProblemChart,
  Powerin23,
  UpcommingElctionCard,
} from '../../../../_metronic/partials/widgets'
import useAPI from '../../../hooks/useAPI'
import {UsersListLoading} from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {MlaCard} from '../../../../_metronic/partials/widgets/_new/cards/MlaCard'
import {PresentStateGov} from '../../../../_metronic/partials/widgets/_new/cards/PresentStateGov'
import {MlaCard2} from '../../../../_metronic/partials/widgets/_new/cards/MlaCard2'
import {NextCmCard} from '../../../../_metronic/partials/widgets/_new/cards/NextCmCard'
import {array} from 'yup'
import {CustomDougnutChartCard} from '../../../../_metronic/partials/widgets/_new/cards/CustomDougnutChartCard'
import {
  CustomHorizontalChart,
  CustomPieChart,
} from '../../../../_metronic/partials/widgets/charts/CustomPieChart'
import {BoothStatusCard} from '../../../../_metronic/partials/widgets/_new/cards/BoothStatusCard'
import {ActivityProgress} from '../../../../_metronic/partials/widgets/_new/cards/ActivityProgress'
import moment from 'moment'
import {getValidDate} from '../../../helpers/CommonHelpers'
import {
  createBoothCountbyAcNoService,
  dashboardDataService,
  getBoothVolCountbyAcNoService,
} from '../../../services/Services'
import {CreateAppModal, Dropdown1, FilterDropdown} from '../../../../_metronic/partials'

const InchargeDashboard = () => {
  const intl = useIntl()
  const assemblyNames = assembly.data.rows
  const [totalBoothsData, setTotalToBeCreated] = useState(null)
  const [totalCreatedByMe, setTotalCreatedByMe] = useState(null)
  const [remainning1, setRemaining1] = useState([])
  const [touchCount, setTouch] = useState([])
  const [untouchCount, setUntouch] = useState([])
  const [completedCount, setCompleted] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  // const [assemblyNames, setAssemblyNames] = useState([])
  const [booth, setBooth] = useState([])
  const [AC_NO, setACNo] = useState(assemblyNames?.[0]?.AC_NO)
  const [updatesCount, setUpdatesCount] = useState(0)
  const [startDate, setStartDate] = useState(moment().add(-30, 'day').toDate())
  const [lastDate, setLastDate] = useState(new Date())

  // For filter Functions
  const [phoneFilter, setPhoneFilter] = useState('')
  const [genderFilter, setGenderFilter] = useState('')
  const [ageGroupFilter, setAgeGroupFilter] = useState('')
  const [religionFilter, setreligionFilter] = useState('')
  const [casteFilter, setCasteFilter] = useState('')
  const [professionFilter, setProfessionFilter] = useState('')
  const [educationFilter, setEducationFilter] = useState('')

  const submitFilter = (e) => {
    e.preventDefault()
    console.log(genderFilter)
  }

  const {loading: getAssemblyDatasLoading, callApi: callGetAssemblyDashboardApi} = useAPI({
    loadingState: true,
  })

  // let startWeek = moment(new Date()).startOf('week').format('YYYY-MM-DD')
  // let endWeek = moment(new Date()).endOf('week').format('YYYY-MM-DD')

  const onChangeTimeRange = (e) => {
    const sDate = moment().add(e.target.value, 'day').toDate()
    setStartDate(sDate)
    setLastDate(new Date())
  }

  const getAssemplyData = () =>
    new Promise((resolve) => {
      const fromDate = getValidDate(startDate, 'YYYY-MM-DD')
      const toDate = getValidDate(lastDate, 'YYYY-MM-DD')
      const pStationsThroughId = allPStations.filter((item) => item?.AC_NO === AC_NO)
      const psBuildingIds = pStationsThroughId.map((item) => item.PSBUILDING_ID)
      const filteredBooths = allBooths.filter(
        (item) => item.AC_NO === AC_NO && psBuildingIds.includes(item.PS_BUILDINGS_ID)
      )
      // console.log("aman------------------",filteredBooths)
      const partNosString = filteredBooths.map((item) => item.PART_NO)
      // console.log(partNosString
      // const UserLogins = JSON.parse(localStorage.getItem('userLogins'))
      // console.log('User Role Id', UserLogins.ACCESS[0].PSBUILDING[0].PSBUILDING_ID)
      setTotalToBeCreated(partNosString.length)
      // console.log('Asssembly data', assembly.data.rows[0])

      var body = {
        part_no: partNosString,
      }
      getBoothVolCountbyAcNoService(AC_NO).then((res) => {
        // console.log('from backend',res?.data?.count);
        setUpdatesCount(res?.data?.count)
        setTotalCreatedByMe(res?.data?.count)
      })

      // axios.get(`/BV/count?AC_NO=${AC_NO}`).then((data) => {
      //   console.log('total created by me',data.data.count)
      //   setTotalCreatedByMe(data.data.count)
      // })
      // axios
      //   .get(`/reports/dashboard?AC_NO=${AC_NO}&startDate=${fromDate}&endDate=${toDate}`)
      const data = {AC_NO: AC_NO, startDate: fromDate, endDate: toDate}
      dashboardDataService(data).then((res) => {
        setDashboardData(res?.data?.count)
        // console.log('amannn', res?.data?.count?.chart);
        // console.log('DAshborad...', res?.data?.count?.activity_progress?.allVoters)
      })
      createBoothCountbyAcNoService(AC_NO, body).then((data) => {
        // console.log('touchCount', data.data.data)
        setBooth(data.data.data)
        const completedBoothData = []
        const touchBoothData = []
        const untouchBoothData = []
        data.data.data.map((item) => {
          if (item.report === 'Completed') {
            completedBoothData.push(item?.report)
          } else if (item.report === 'Touched') {
            touchBoothData.push(item?.report)
            // setTouch((oldArray) => [...oldArray, item.report])
          } else {
            untouchBoothData.push(item?.report)
            // setUntouch((oldArray) => [...oldArray, item.report])
          }
        })
        setTouch(touchBoothData)
        setUntouch(untouchBoothData)
        setCompleted(completedBoothData)
        resolve(true)
      })
    })

  useEffect(
    function () {
      callGetAssemblyDashboardApi(getAssemplyData())
    },
    [AC_NO, startDate, lastDate]
  )

  // const repeatCard = [1, 2, 3, 4, 5]

  return (
    <div>
      <div
        className='d-sm-flex  text-center justify-content-around bg-white py-5 align-items-center fw-bold'
        style={{color: '#9797A5'}}
      >
        <div className='d-sm-flex gap-2 align-items-center'>
          Time range
          <div className=''>
            <select
              name=''
              id=''
              onChange={onChangeTimeRange}
              className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
            >
              <option value='-30'>Last Month</option>
              <option value='-7'>Last Week</option>
              <option value='-1'>Last Day</option>
              <option value='0'>Today</option>
              {/* <option value=''>Last Day</option> */}
            </select>
          </div>
        </div>
        <div className='d-sm-flex gap-3 align-items-center my-sm-0 my-5'>
          <div>Date</div>
          <div>
            <input
              type='date'
              className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
              value={getValidDate(startDate, 'YYYY-MM-DD')}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className=''>-</div>
          <div>
            <input
              type='date'
              className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
              value={getValidDate(lastDate, 'YYYY-MM-DD')}
              onChange={(e) => setLastDate(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='container '>
        <div className='d-flex align-items-center mb-10 justify-content-center'>
          <h2
            style={{
              color: '#0a8202 ',
              fontSize: '20px',
            }}
          >
            Assembly - {}
          </h2>
          <div className='form-floating p-3'>
            <select
              className='p-2 bg-gray rounded border-success fw-bold '
              style={{color: '#0a8202', cursor: 'pointer'}}
              id='floatingSelect1'
              aria-label='Floating label select example'
              onChange={(e) => setACNo(e.target.value)}
              value={AC_NO}

              // required
            >
              {assemblyNames.map((item) => {
                return (
                  <option key={item.AC_NO} value={item?.AC_NO}>
                    {item.NAME_EN}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
        {/* begin::Row */}
        {getAssemblyDatasLoading ? (
          <UsersListLoading />
        ) : (
          <>
            <div className='d-sm-flex justify-content-around fw-bold fs-6 text-gray-800 my-5'>
              <div> Total Polling Stations: {dashboardData?.totalPoolingSts}</div>
              <div> Total Booths: {dashboardData?.totalBooths}</div>
              <div> Total Voters: {dashboardData?.totalVoters}</div>
              <div> Total Houses:{dashboardData?.totalHouses}</div>
              <div>
                {' '}
                Total Booth Members:{' '}
                {dashboardData?.totalBoothMembers ? dashboardData.totalBoothMembers : 0}
              </div>
            </div>
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* row g-5 g-xl-10 mb-5 mb-xl-10 w-100 */}
              {/* <div className='d-sm-flex justify-content-center gap-20 w-100'> */}
              <div className='row w-100 gap-8'>
                {/* begin::Col */}

                <CardsWidget7
                  count='0 '
                  text='Families Covered'
                  color='#F64E60'
                  icon={Group}
                  className='col-sm mb-5 mb-xl-10'
                />

                <CardsWidget7
                  color='#181C32'
                  count={dashboardData?.totalVotersPhoneAcquired}
                  text='Phone Numbers Aquired '
                  icon={AddCall}
                  className='col-sm mb-5 mb-xl-10'
                />

                <CardsWidget7
                  color='#44C395'
                  count={dashboardData?.activeUsers}
                  // count2={dashboardData?.inActiveUsers}
                  text='Active Users'
                  icon={AddUser}
                  className='col-sm mb-5 mb-xl-10'
                />

                <CardsWidget7
                  color='#3699FF'
                  icon={AddUser}
                  count={dashboardData?.inActiveUsers}
                  text='Inactive Users'
                  className='col-sm mb-5 mb-xl-10'
                />

                {/* <ListsWidget26 className='h-lg-50' /> */}

                {/* <ListsWidget26 className='h-lg-50' /> */}

                {/* end::Col */}
                {/* Ends Aman Soni Card */}
                {/* begin::Col */}
              </div>
            </div>
            {/* Card 17 and 20 */}
            <div className='row g-10 g-xl-10 mb-5 mb-xl-10'>
              {/* <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                <CardsWidget17
                  touchData={touchCount}
                  untouchData={untouchCount}
                  completedData={completedCount}
                  totalCount={totalBoothsData}
                  booth={booth}
                  AC_NO={AC_NO}
                  user_Id={AC_NO}
                  className='h-md-100 mb-5 mb-xl-10'
                />
              </div>
              <div className='w-md-50 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                <CardsWidget20
                  totalToBeCreated={totalBoothsData}
                  totalCreatedByMe={totalCreatedByMe}
                  AC_NO={AC_NO}
                  className='h-md-100 mb-5 mb-xl-10 '
                  description='Active Projects'
                  color='white'
                />
              </div> */}
              <div className='w-md-50 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                <BoothStatusCard
                  boothActive={touchCount}
                  boothInactive={untouchCount}
                  completedData={completedCount}
                  totalCount={totalBoothsData}
                  AC_NO={AC_NO}
                  className='h-100 mb-5 mb-xl-10 '
                  description='Active Projects'
                  color='white'
                  // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                />
              </div>

              <div className='w-md-50 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                <ActivityProgress
                  allVoters={dashboardData?.activity_progress?.allVoters}
                  votersCovered={dashboardData?.activity_progress?.votersCovered}
                  votersRemaining={dashboardData?.activity_progress?.votersRemaining}
                  AC_NO={AC_NO}
                  className='h-100 mb-5 mb-xl-10 '
                  description='Active Projects'
                  color='white'
                  // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                />
              </div>
            </div>

            <div className='text-center my-10'>
              <button
                style={{
                  color: '#20D489',
                  backgroundColor: 'rgba(32, 212, 137, 0.2)',
                  border: 'none',
                }}
                className={clsx('px-10 py-3 rounded fw-bold shadow bg-gray-600')}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                Search by Filters <FiFilter />
              </button>
              <FilterDropdown
                setPhoneFilter={setPhoneFilter}
                setGenderFilter={setGenderFilter}
                setAgeGroupFilter={setAgeGroupFilter}
                setreligionFilter={setreligionFilter}
                setCasteFilter={setCasteFilter}
                setProfessionFilter={setProfessionFilter}
                setEducationFilter={setEducationFilter}
                submitFilter={submitFilter}
              />
            </div>
            <div className='row g-10 g-xl-10 mb-5 mb-xl-10'>
              {/* begin::Col */}
              {/* <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <MlaCard
                touchData={touchCount}
                untouchData={untouchCount}
                completedData={completedCount}
                totalCount={totalBoothsData}
                booth={booth}
                AC_NO={AC_NO}
                user_Id={AC_NO}
                Heading = {'Are you satisfied with present MLA performance ?'}
                className='h-md-100 mb-5 mb-xl-10'
            />
            </div> */}

              {dashboardData?.chart
                ?.filter((chartItem) => chartItem?.CHART_TYPE === 'pie')
                ?.map((item) => {
                  const data = item?.ANSWERS?.map((ansItem) => ({
                    label: ansItem?.ANS,
                    color: ansItem?.totalReviews === 0 ? '#00000' : ansItem?.COLOR_CODE,
                    value: ansItem?.totalReviews,
                  }))
                  return (
                    <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                      <CustomDougnutChartCard
                        title={item?.QUESTION}
                        className='h-md-100 mb-5 mb-xl-10'
                        data={data}
                        // data={[
                        //   {label: 'Bad', color: '#00A3FF', value: 20},
                        //   {label: 'Good', color: '#F64E60', value: 10},
                        //   {label: 'Best', color: '#50CD89', value: 7},
                        // ]}
                      />
                    </div>
                  )
                })}

              {/* <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <PresentStateGov
                touchData={touchCount}
                untouchData={untouchCount}
                completedData={completedCount}
                totalCount={totalBoothsData}
                booth={booth}
                AC_NO={AC_NO}
                user_Id={AC_NO}
                className='h-md-100 mb-5 mb-xl-10'
            />
            </div> */}
            </div>
            {/* <div className='row g-10 g-xl-10 mb-5 mb-xl-10' >
          begin::Col
          <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <MlaCard2
                touchData={touchCount}
                untouchData={untouchCount}
                completedData={completedCount}
                totalCount={totalBoothsData}
                booth={booth}
                AC_NO={AC_NO}
                user_Id={AC_NO}
                className='h-md-100 mb-5 mb-xl-10'
            />
            </div>

          <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <NextCmCard
                touchData={touchCount}
                untouchData={untouchCount}
                completedData={completedCount}
                totalCount={totalBoothsData}
                booth={booth}
                AC_NO={AC_NO}
                user_Id={AC_NO}
                className='h-md-100 mb-5 mb-xl-10'
            />
            </div>
        </div> */}
            {/* <div className='w-md-100 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-20'> */}
            {dashboardData?.chart
              ?.filter((chartItem) => chartItem?.CHART_TYPE === 'horizontal')
              ?.map((item) => {
                const data = item?.ANSWERS?.map((ansItem) => ({
                  label: ansItem?.ANS,
                  color: ansItem?.COLOR_CODE,
                  value: ansItem?.totalReviews,
                }))

                // console.log('rk 2', data)
                return (
                  <div className=' w-md-100 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
                    <CustomPieChart
                      title={item?.QUESTION}
                      horizontal={true}
                      className='h-md-100 mb-5 mb-xl-10'
                      data={data}
                    />
                  </div>
                )
              })}
            {/* <UpcommingElctionCard /> */}
            {/* </div> */}
          </>
        )}
      </div>
    </div>
  )
}

export {InchargeDashboard}

import axios from '../../../fetchApi/Api'
import React, {useEffect, useState} from 'react'
import allBooths from './../../../../JSON/booth.json'
import allPStations from './../../../../JSON/pstation.json'
import {useLocation} from 'react-router-dom'
// import AllBoth from "./../../../../JSON/booth.json"
// import axios from 'axios'
import {
  TablesWidget1,
  TablesWidget2,
  TablesWidget3,
  TablesWidget4,
  TablesWidget5,
  TablesWidget6,
  TablesWidget7,
  TablesWidget8,
  TablesWidget9,
  TablesWidget10,
  TablesWidget11,
  TablesWidget12,
  TablesWidget13,
  TablesWidget14,
} from '../../../../_metronic/partials/widgets'

const Boothdetails = () => {
  const Location = useLocation()
  const user = Location.state

  const [touch, setTouch] = useState([])
  const [untouch, setUntouch] = useState([])
  const [completed, setCompleted] = useState([])
  const [booth, setBooth] = useState([])
  const [booth1, setBooth1] = useState([])
  
  useEffect(function () {
    const pStationsThroughId = allPStations.filter((item) => item?.AC_NO === user?.AC_NO)
    const psBuildingIds = pStationsThroughId.map((item) => item.PSBUILDING_ID)
    const filteredBooths = allBooths.filter(
      (item) => item.AC_NO === user?.AC_NO && psBuildingIds.includes(item.PS_BUILDINGS_ID)
    )
    const partNosString = filteredBooths.map((item) => item.PART_NO)
    // setData1(partNosString.length)
    setBooth(filteredBooths)
    var body = {
      part_no: partNosString,
    }
    axios.post(`/booth/report?AC_NO=${user?.AC_NO}`, body).then((data) => {
      // setBooth(data.data.data)
      var array1 = filteredBooths
      var array2 = data.data.data
      // const cars1IDs = new Set(data.data.data.map(({id}) => id))
      // const combined = [...data.data.data, ...filteredBooths.filter(({id}) => !cars1IDs.has(id))]
      data.data.data.map((i) => {
        array1 = array1.filter((item) => item.PART_NO !== i.part_no)
        // array1.pop(i.part_no)
      })
      setUntouch(array1)
      filteredBooths.map((i) => {
        data.data.data.map((item) => {
          if (item.report === 'Completed') {
            if (item.part_no === i.PART_NO) {
              i.totalBV = item.totalBV
              setCompleted((oldArray) => [...oldArray, i])
              setBooth1((oldArray) => [...oldArray, i])
            }
          } else if (item.report === 'Touched') {
            if (item.part_no === i.PART_NO) {
              i.totalBV = item.totalBV
              setTouch((oldArray) => [...oldArray, i])
              // console.log('Tocheddd', i)
            }
          }
          // else {
          //   setUntouch((oldArray) => [...oldArray, i])
          //   // setUntouch((oldArray) => [...oldArray, item.report])
          //   console.log('unTocheddd', i)
          // }
        })
      })
    })
  }, [])
  const [b1, setB1] = useState(false)
  const [b2, setB2] = useState(false)
  const [b3, setB3] = useState(false)

  return (
    <>
      <div className='container mt-10'>
        <TablesWidget14 className='mb-5 mb-xl-8' AC_NO={user.AC_NO} />
      </div>
    </>
  )
}
export {Boothdetails}

import axios from '../../../../app/fetchApi/Api'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useLocation} from 'react-router-dom'
// import axios from 'axios'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {useNavigate} from 'react-router-dom'

const UserBoothTable = (props) => {
  const [update, setUpdate] = useState([])
const [updatesLoading, setUpdatesLoading] = useState(true);
  const navigate = useNavigate()

  // console.log('rk updatesLoading', updatesLoading)
  const handleVoter = (i) => {
    // console.log('i--------------', i)
    navigate('/booth-details', {state: i})
  }

  var location = useLocation()
  const user = location.state
  const init = async () => {
    if(props?.boothTableData?.length > 0) {
      const promise = props?.boothTableData.map((boothData) => {
        return axios
      .get(`/voters/count?AC_NO=${props?.AC_NO}&Booth_NO=${boothData?.PART_NO}&userId=${user?.user_Id}`).then((res) => res?.data?.count)
    })
      await Promise.all(promise).then((res) => {
      setUpdate(res);
      setUpdatesLoading(false);
      })
    }
  }
  useEffect(() => {
init();
  }, [props?.boothTableData])

  return (
    <>
      <h2
        style={{
          display: 'flex',
          color: '#0a8202 ',
          justifyContent: 'center',
          marginBottom: '30px',
          fontSize: '20px',
        }}
      >
        {user.PART_NAME_EN}
      </h2>
      <div className={`card ${props.className} shadow`}>
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'></th>
                  <th className='min-w-150px'>S.no</th>
                  <th className='min-w-120px'>PART NO</th>
                  <th className='min-w-120px'>PART NO EN</th>
                  <th className='min-w-120px'>PART NO V1</th>
                  <th className='min-w-120px'>Updates</th>
                  <th className='min-w-120px'></th>
                </tr>
              </thead>
              <tbody>
                {props.boothTableData.map((i, index) => (
                  <>
                    <tr
                      className='text-dark fw-bold fs-6'
                      style={{cursor: 'pointer'}}
                      onClick={() => handleVoter(i)}
                    >
                      <td></td>
                      <td>
                        <a className='text-dark fw-bold  fs-6'>{index + 1}</a>
                      </td>
                      <td className='text-dark fw-bold text-hover-success fs-6'>{i.PART_NO}</td>
                      <td className='text-dark fw-bold text-hover-success fs-6'>
                        {i.PART_NAME_EN}
                      </td>
                      <td className='text-dark fw-bold text-hover-success fs-6'>
                        {i.PART_NAME_V1}
                      </td>
                      <td>
                        {updatesLoading &&  <span className='spinner-border spinner-border-sm align-middle ms-2'/> }
                        {update.map((i, ind) => (
                          <>
                            {index == ind ? (
                              <a
                                className='text-center fw-bold text-white d-block mb-1 fs-6 rounded w-25'
                                style={{background: '#0a8202'}}
                              >
                                {i}
                              </a>
                            ) : (
                              ''
                            )}
                          </>
                        ))}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default UserBoothTable

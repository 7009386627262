/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from '../../../../app/fetchApi/Api'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useLocation} from 'react-router-dom'
// import axios from 'axios'
import {BsFillTelephoneFill} from 'react-icons/bs'
const TablesWidget14 = (props) => {
  const [data, setData] = useState([])
  const [update, setUpdate] = useState([])

  var location = useLocation()
  const user = location.state
  useEffect(function () {
    var part_no = user.PART_NO
    // alert(part_no)
    axios
      .get(
        `/admin/getUsers?userId=57&limit=10000&offset=0&AC_NO=${user?.AC_NO}&PART_NO=${part_no}&ROLE_ID=2`
      )
      .then((r) => {
        setData(r.data.data)

        r.data.data.map((i) => {
          axios
            .get(
              `/voters/count?AC_NO=${user?.AC_NO}&Booth_NO=${i.USER_ID}&userId=22383`
            )
            .then((result) => {
              setUpdate((oldArray) => [...oldArray, result.data])
            })
        })
        // console.log('r.data-------------', r.data.data)
      })
  }, [])
  // console.log('result.data-------------', update)
  useEffect(function () {})
  const handle = () => {}
  return (
    <>
      <h2
        style={{
          display: 'flex',
          color: '#0a8202 ',
          justifyContent: 'center',
          marginBottom: '30px',
          fontSize: '20px',
        }}
      >
        {user.PART_NAME_EN}
      </h2>
      <div className={`card ${props.className} shadow`}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 pt-0'> */}
        {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Orders</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 orders</span>
        </h3> */}
        {/* <div className='card-toolbar'></div>
      </div> */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'>
                    {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div> */}
                  </th>
                  {/* AC_NO : 2 PART_NAME_EN : "PRATHMIK VIDHYALAY KAMRA NUMBER-1 FAHDPURI" PART_NAME_V1 :
                "प्राथमिक विद्यालय कमरा नम्‍बर-1 फन्‍दपुरी" PART_NO : 296 PS_BUILDINGS_ID : "002159" */}
                  <th className='min-w-150px'>S.no</th>
                  {/* <th className='min-w-140px'>AC_NO</th> */}
                  <th className='min-w-120px'>Name</th>
                  <th className='min-w-120px'>Updates</th>

                  <th className='min-w-120px'>Phone</th>
                  {/* <th className='min-w-120px'>PART NAME V1</th> */}
                  {/* <th className='min-w-120px'>PS_BUILDINGS_ID</th> */}
                  {/* <th className='min-w-100px text-end'>Actions</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data.map((i, index) => (
                  <>
                    {/* {update.map((item) => ( */}
                    <tr className='text-dark fw-bold fs-6' style={{cursor: 'pointer'}}>
                      <td></td>
                      <td>
                        <a className='text-dark fw-bold  fs-6'>
                          {index + 1}
                        </a>
                      </td>
                      <td className='text-dark fw-bold text-hover-success fs-6'>{i.NAME}</td>
                      <td>
                        {update.map((i, ind) => (
                          <>
                            {index == ind ? (
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                              >
                                {i.count}
                              </a>
                            ) : (
                              ''
                            )}
                          </>
                        ))}
                      </td>
                      <td>
                        <a
                          href={`tel:${i.PHONE}`}
                          className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                        >
                          <span className='p-3' style={{color: '#0a8202'}}>
                            <BsFillTelephoneFill />
                          </span>
                          {i.PHONE}{' '}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {i.PART_NAME_V1}
                        </a>
                      </td>
                    </tr>
                    {/* ))}{' '} */}
                  </>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {TablesWidget14}

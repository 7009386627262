import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCG5ttHW3dkHLJA250_v-jObYSPywr3-YU",
  authDomain: "politrack-2327c.firebaseapp.com",
  projectId: "politrack-2327c",
  storageBucket: "politrack-2327c.appspot.com",
  messagingSenderId: "1015167127839",
  appId: "1:1015167127839:web:8788a6d97055aa23b3602a",
  measurementId: "G-0W8PQVNT6G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app

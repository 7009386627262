import DoughnutChart from '../../../../../app/customComponents/doughnutChart/DoughnutChart'
// import DonutChart from 'react-donut-chart'

const CustomDougnutChartCard = ({className, touchData, completedData, totalCount,
title,
data = [],
}) => {
  return (
    <div className={`card card-flush shadow ${className}`}>
      <div className='card-header pt-5'>
        {/* <div className='card-title d-flex flex-column'>
          <span className='text-black-400 pt-1 fw-semibold fs-6'>
            {' '}
            <div style={{fontWeight: 'bold'}}>Tasks/Booths Status</div>
          </span>
        </div> */}
        <div className='d-flex justify-content-between fw-bold text-black fs-20 opacity-75 w-100 mt-5 mb-2'>
          <span className='text-black fw-bold fs-5' style={{fontWeight: 'bold'}}>
            {' '}
            <b>{title}</b>
          </span>{' '}
        </div>
      </div>
      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div style={{width: '150px'}}>
            <DoughnutChart data={data}/>
          </div>
        </div>

        <div className='d-flex flex-column justify-content-center flex-row-fluid'>
          {data?.map((item) => (
          <div className='d-flex fw-semibold  align-items-center'>
            <div
              className='bullet  rounded-2  me-3'
              style={{background: item?.color??'#808080', width: '19px', height: '6px'}}
            ></div>
            <div className='text-gray-500 flex-grow-1 me-4'>{item?.label}</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{item?.value}</div>
          </div>
          ))}
        </div>
      </div>
    </div>
  )
}



export {CustomDougnutChartCard}

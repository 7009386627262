/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from '../../../fetchApi/Api'
import {useState, useEffect} from 'react'
// import axios from 'axios'
import AddUser from '../../../Icons/AddUser.png'
import AddCall from '../../../Icons/AddCall.png'
import Group from '../../../Icons/Group.png'
import {CardsWidget7} from '../../../../_metronic/partials/widgets'
import {BoothVolenteersTable} from '../../../../_metronic/partials/widgets/tables/BoothVolenteersTable'
import {VolunteerDashboardReportCard} from '../../../../_metronic/partials/widgets/_new/cards/VolunteerDashboardReportCard'
import useAPI from '../../../hooks/useAPI'
import {UsersListLoading} from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {getValidDate} from '../../../helpers/CommonHelpers'
import moment from 'moment'
import {BoothVolunteerReportCard} from '../../../../_metronic/partials/widgets/_new/cards/BoothVolunteerReportCard'
import {getBoothVolDashboardDataSevice, getVoluteerCountService} from '../../../services/Services'

const VolunteerDashboard = () => {
  const [boothVolunteerReportData, setBoothVolunteerReportData] = useState()
  const [startDate, setStartDate] = useState(moment().add(-30, 'day').toDate())
  const [lastDate, setLastDate] = useState(new Date())
  const [dashboardData, setDashboardData] = useState([])
  const userDataLS = localStorage.getItem('userLogins')
  const userData = JSON.parse(userDataLS)
  const userId = userData?.USER_ID
  const AC_NO = userData.ACCESS[0].AC_NO
  console.log("json", userData);

  const {loading: getBoothVolunteerReportLoading, callApi: callGetBoothVolunteerReportApi} = useAPI(
    {loadingState: true}
  )

  const onChangeTimeRange = (e) => {
    const sDate = moment().add(e.target.value, 'day').toDate()
    setStartDate(sDate)
    setLastDate(new Date())
  }

  const getBoothVolunteerReportAPI = () =>
    new Promise((resolve, reject) => {
      const fromDate = getValidDate(startDate, 'YYYY-MM-DD')
      const toDate = getValidDate(lastDate, 'YYYY-MM-DD')
      const userDataLS = localStorage.getItem('userLogins')
      const userData = JSON.parse(userDataLS)
      const userId = userData?.USER_ID
      const AC_NO = userData.ACCESS[0].AC_NO

      const data = {AC_NO: AC_NO, userId: userId, startDate: fromDate, endDate: toDate}
      getVoluteerCountService(data)
        .then((res) => {
          setBoothVolunteerReportData({
            totalVoters: res?.data?.totalVoters,
            totalVotersUpdated: res?.data?.count,
            totalVotersUpdatedByMe: res?.data?.count,
          })
          // console.log('volDash', res)
          resolve(res)
        })
        .catch(reject)

      getBoothVolDashboardDataSevice(data).then((res) => {
        setDashboardData(res?.data?.count)
        console.log('Dashboard count',res?.data?.count)
      })
    })

  useEffect(
    function () {
      callGetBoothVolunteerReportApi(getBoothVolunteerReportAPI())
    },
    [startDate, lastDate]
  )

  if (getBoothVolunteerReportLoading) return <UsersListLoading />
  return (
    <div>
      <div
        className='d-sm-flex  text-center justify-content-around bg-white py-5 align-items-center fw-bold w-100'
        style={{
          color: '#9797A5',
          // position: 'fixed',
          // zIndex: 1,
        }}
      >
        <div
          className='d-sm-flex gap-2 align-items-center'
          // style={{overflow: 'hidden', position: 'fixed', top: '0'}}
        >
          Time range
          <div className=''>
            <select
              name=''
              id=''
              onChange={onChangeTimeRange}
              className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
            >
              <option value='-30'>Last Month</option>
              <option value='-7'>Last Week</option>
              <option value='-1'>Last Day</option>
              <option value='0'>Today</option>
              {/* <option value=''>Last Day</option> */}
            </select>
          </div>
        </div>
        <div className='d-sm-flex gap-3 align-items-center my-sm-0 my-5'>
          <div>Date</div>
          <div>
            <input
              type='date'
              className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
              value={getValidDate(startDate, 'YYYY-MM-DD')}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className=''>-</div>
          <div>
            <input
              type='date'
              className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
              value={getValidDate(lastDate, 'YYYY-MM-DD')}
              onChange={(e) => setLastDate(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='container mt-10'>
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
            color: '#0a8202 ',
            fontSize: '20px',
            overflow: 'auto',
          }}
        >
          Welcome Booth Volunteers
        </h2>

        <div className='d-sm-flex justify-content-around fw-bold fs-6 text-gray-800 my-5'>
          <div> Total Voters: {dashboardData?.totalVoters}</div>
          <div> Total Houses:{dashboardData?.totalHouses}</div>
          <div>
            Total Booth Members:
            {dashboardData?.totalBoothMembers ? dashboardData.totalBoothMembers : 0}
          </div>
        </div>

        <BoothVolenteersTable />
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* row g-5 g-xl-10 mb-5 mb-xl-10 w-100 */}
          {/* <div className='d-sm-flex justify-content-center gap-20 w-100'> */}
          <div className='container row w-md-75 gap-6 mt-10'>
            {/* begin::Col */}

            <CardsWidget7
              color='#3699FF'
              icon={AddUser}
              count={dashboardData?.totalVotersUpdated}
              text='Total Voters Updated'
              className='col-sm mb-xl-10 h-md-5'
            />

            <CardsWidget7
              color='#44C395'
              count={dashboardData?.totalVotersUpdatedByMe}
              text='Voters Updated By Me'
              icon={Group}
              className='col-sm mb-5 mb-xl-10'
            />

            <CardsWidget7
              count={dashboardData?.totalHouses}
              text='Families Covered'
              color='#F64E60'
              icon={Group}
              className='col-sm mb-5 mb-xl-10'
            />
            <CardsWidget7
              color='#181C32'
              count={dashboardData?.totalVotersPhoneAcquired}
              text='Phone Numbers Aquired'
              icon={AddCall}
              className='col-sm mb-5 mb-xl-10'
            />
          </div>
        </div>
        <div className='row g-10 g-xl-10 mb-5 mb-xl-10 w-md-75 mx-auto'>
          {/* <VolunteerDashboardReportCard
          chartSize={150}
          totalCount={boothVolunteerReportData?.totalVoters ?? 0}
          totalVotersUpdated={boothVolunteerReportData?.totalVotersUpdated ?? 0}
          totalVotersUpdatedByMe={boothVolunteerReportData?.totalVotersUpdatedByMe ?? 0}
          className='h-md-100 mb-5 mb-xl-10'
        /> */}
          <BoothVolunteerReportCard
            allVoters={dashboardData?.my_report?.allVoters}
            votersCovered={dashboardData?.my_report?.votersCovered}
            votersRemaining={dashboardData?.my_report?.votersRemaining}
            AC_NO={AC_NO}
            className='h-100 mb-5 mb-xl-10 '
            description='Active Projects'
            color='white'
            // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
      </div>
    </div>
  )
}

export {VolunteerDashboard}

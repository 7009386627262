import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {FiFilter} from 'react-icons/fi'
import assembly from '../../../../JSON/assembly.json'
import moment from 'moment'
import {getValidDate} from '../../../../app/helpers/CommonHelpers'
import {getQandAforfilterService, getReportsDataService} from '../../../../app/services/Services'
import {CSVLink} from 'react-csv'

const AssemblyReportTable = (props) => {
  const navigate = useNavigate()
  const [update, setUpdate] = useState([])
  const [updatesLoading, setUpdatesLoading] = useState(true)
  const [phoneFilter, setPhoneFilter] = useState(true)
  const [genderFilter, setGenderFilter] = useState('')
  const [agefromFilter, setAgeFromFilter] = useState('')
  const [agetoFilter, setAgeToFilter] = useState('')
  const [religionFilter, setreligionFilter] = useState('')
  const [casteFilter, setCasteFilter] = useState('')
  const [professionFilter, setProfessionFilter] = useState('')
  const [educationFilter, setEducationFilter] = useState('')
  const [questionFilter, setQuestionFilter] = useState('')
  const [responseFilter, setResponseFilter] = useState('')
  const [startDate, setStartDate] = useState(moment().add(-30, 'day').toDate())
  const [lastDate, setLastDate] = useState(new Date())

  //Table data from Api
  const [tableData, setTableData] = useState([])
  //Get QandA response
  const [qadata, setQadata] = useState([])
  const [responseAns, setResponseAns] = useState([])

  const assemblyNames = assembly.data.rows
  const AC_NO = assemblyNames?.[0]?.AC_NO

  const onChangeTimeRange = (e) => {
    const sDate = moment().add(e.target.value, 'day').toDate()
    setStartDate(sDate)
    setLastDate(new Date())
    console.log('dates', startDate)
  }

  const fromDate = getValidDate(startDate, 'YYYY-MM-DD')
  const toDate = getValidDate(lastDate, 'YYYY-MM-DD')

  const data = {
    AC_NO: AC_NO,
    startDate: fromDate,
    endDate: toDate,
    phone_no: phoneFilter,
    gender: genderFilter,
    religion: religionFilter,
    caste: casteFilter,
    profession: professionFilter,
    education: educationFilter,
    questionId: questionFilter,
    ansId: responseFilter,
    ageFrom: agefromFilter,
    ageTo: agetoFilter,
  }

  const resetFilter = () => {
    setPhoneFilter('')
    setGenderFilter('')

    setAgeFromFilter('')
    setAgeToFilter('')
    setreligionFilter('')
    setCasteFilter('')
    setProfessionFilter('')
    setEducationFilter('')
    setQuestionFilter('')
    setResponseFilter('')
  }

  const fetchQA = () => {
    getQandAforfilterService().then((Response) => {
      // console.log('filter QA', Response?.data.data)
      setQadata(Response?.data?.data)
    })
  }

  useEffect(() => {
    getReportsDataService(data).then((Response) => {
      setTableData(Response?.data?.data)
    })
    fetchQA()
  }, [
    startDate,
    AC_NO,
    fromDate,
    toDate,
    genderFilter,
    religionFilter,
    casteFilter,
    professionFilter,
    educationFilter,
    questionFilter,
    religionFilter,

    agefromFilter,
    agetoFilter,
    responseFilter,
  ])

  return (
    <div className='d-sm-flex '>
      <div
        className='d-flex-column h-sm-100vh me-o px-sm-5 px-10 px-0 justify-content-between w-sm-25'
        style={{
          // width: '20%',
          height: '100vh',
          background: '#EBEBEB',
          // background: '#171717',
          // position: 'fixed',
          // display: 'flex',
        }}
      >
        {/* search by filter */}
        <div className='my-10'>
          <h3 className='text-center'>
            Search by Filters{' '}
            <span>
              <FiFilter />
            </span>
          </h3>
        </div>
        {/* Phone Number */}
        <div className='d-flex gap-10 fw-bold mx-3 my-5'>
          Phone Number
          <div className='form-check form-switch '>
            <input
              type='checkbox'
              id='phoneNumber'
              name='phoneNumber'
              defaultChecked={true}
              value={phoneFilter}
              onChange={() => setPhoneFilter(!phoneFilter)}
            />
          </div>
        </div>
        <div className='d-flex flex-column gap-5 fw-bold'>
          {/* Gender */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Gender</p>
            <select
              name=''
              id=''
              value={genderFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setGenderFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='M'>Male</option>
              <option value='F'>Female</option>
            </select>
          </div>

          {/* Age Group */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Age Group</p>
            {/* drop down starts */}
            <div className='d-flex w-50 align-items-center gap-1'>
              <select
                name=''
                id=''
                value={agefromFilter}
                className='p-2 bg-gray rounded border-0 w-50 fw-bold text-center cursor-pointer'
                onChange={(e) => {
                  setAgeFromFilter(e.target.value)
                }}
              >
                <option value=''>from</option>
                <option value='18'>18</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='70'>70</option>
              </select>
              -
              <select
                name=''
                id=''
                value={agetoFilter}
                className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
                onChange={(e) => {
                  setAgeToFilter(e.target.value)
                }}
              >
                <option value=''>to</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='70'>70</option>
                <option value='200'>70+</option>
              </select>
            </div>
          </div>
          {/* drop down ends */}

          {/* Religion */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Religion</p>
            <select
              name=''
              id=''
              value={religionFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setreligionFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Hindu'>Hindu</option>
              <option value='MUslim'>Muslim</option>
              <option value='Christian'>Christian</option>
              <option value='Jain'>Jain</option>
              <option value='Buddhist'>Buddhist</option>
              <option value='Parsi'>Parsi</option>
              <option value='Sikh'>Sikh</option>
              <option value='Other'>Other</option>
            </select>
          </div>
          {/* Caste */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Caste</p>
            <select
              name=''
              id=''
              value={casteFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setCasteFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Vokkaliga'>Vokkaliga</option>
              <option value='Bramhin'>Bramhin</option>
              <option value='Lingayath'>Lingayath</option>
              <option value='SC'>SC</option>
              <option value='ST'>ST</option>
              <option value='Muslim'>Muslim</option>
              <option value='Christian'>Christian</option>
              <option value='Golla'>Golla</option>
              <option value='Kuruba'>Kuruba</option>
              <option value='Savitha Samaja'>Savitha Samaja</option>
              <option value='Balajiga'>Balajiga</option>
              <option value='Jain'>Jain</option>
              <option value='Marwadi'>Marwadi</option>
              <option value='Others'>Others</option>
            </select>
          </div>
          {/* Profession */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Profession</p>
            <select
              name=''
              id=''
              value={professionFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setProfessionFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Farmer'>Farmer</option>
              <option value='Private Employee'>Private Employee</option>
              <option value='Self-employee (Business)'>Self-employee (Business)</option>
              <option value='Govt Employee'>Govt Employee</option>
            </select>
          </div>
          {/* Education */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Education</p>
            <select
              name=''
              id=''
              value={educationFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setEducationFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='10th or Below'>10th or Below</option>
              <option value='10+2/Intermediate'>10+2/Intermediate</option>
              <option value='Graduate'>Graduate</option>
              <option value='Post Graduate or Above'>Post Graduate or Above</option>
            </select>
          </div>

          {/* Not selected Question */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Select Question</p>
            <select
              name=''
              id=''
              value={questionFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setQuestionFilter(e.target.value)
                setResponseAns(
                  qadata.filter((item) => item.QUESTION_ID == e.target.value)[0].ANSWERS
                )
              }}
            >
              <option value=''>Not selected</option>
              {qadata.map((item) => (
                <option value={item?.QUESTION_ID}>{item?.QUESTION}</option>
              ))}
              {/* <option value='satisfed'>Are you satisfied ...</option> */}
            </select>
          </div>

          {/* Response */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Response</p>
            <select
              name=''
              id=''
              value={responseFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setResponseFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              {responseAns.map((item) => (
                <option value={item?.ANS_ID}>{item?.ANS}</option>
              ))}
            </select>
          </div>
          <div
            className='btn p-3 text-white rounded cursor-pointer mx-auto mt-10'
            style={{background: '#0a8202'}}
            onClick={resetFilter}
          >
            Reset filters
          </div>
        </div>
      </div>

      <div className='mx-auto w-100 align-items-center'>
        <div
          className='d-sm-flex  text-center justify-content-around  bg-white py-5 align-items-center fw-bold'
          style={{color: '#9797A5'}}
        >
          <div className='d-sm-flex gap-2 align-items-center'>
            Time range
            <div className=''>
              <select
                name=''
                id=''
                onChange={onChangeTimeRange}
                className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
              >
                <option value='-30'>Last Month</option>
                <option value='-7'>Last Week</option>
                <option value='-1'>Last Day</option>
                <option value='0'>Today</option>
                {/* <option value=''>Last Day</option> */}
              </select>
            </div>
          </div>
          <div className='d-sm-flex gap-3 align-items-center'>
            <div>Date</div>
            <div>
              <input
                type='date'
                className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
                value={getValidDate(startDate, 'YYYY-MM-DD')}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className=''>-</div>
            <div>
              <input
                type='date'
                className='pe-5 py-2 ps-1 border border-gray-400 rounded cursor-pointer'
                value={getValidDate(lastDate, 'YYYY-MM-DD')}
                onChange={(e) => setLastDate(e.target.value)}
              />
            </div>
          </div>

          <div
            className='p-3 rounded my-sm-0 my-5 mx-sm-0 mx-20 cursor-pointer'
            style={{background: '#0a8202'}}
          >
            <CSVLink className='text-white' data={tableData} filename={'assembly-report.csv'}>
              Download
            </CSVLink>
          </div>
        </div>
        <h2 className='text-center my-10 fw-bold fs-1'>Assemly Test</h2>

        <div
          className={`card ${props.className} shadow text-center mx-auto`}
          style={{width: '80%'}}
        >
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold fs-6 text-black  '>
                    {/* <th className='w-auto'></th> */}
                    <th className='w-auto'>S.no</th>
                    <th className='w-auto'>Name</th>
                    <th className='w-auto'>Gender</th>
                    <th className='w-auto'>Age group</th>
                    <th className='w-auto'>Phone number</th>
                    <th className='w-auto'>Religion</th>
                    <th className='w-auto'>Cast</th>
                    <th className='w-auto'>Profession</th>
                    <th className='w-auto'>Education</th>
                    {/* <th className='w-auto'></th> */}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((item, index) => (
                    <tr className='fs-6 fw-bold text-gray-800'>
                      <td>{index + 1}</td>
                      <td className='text-black'>{item?.name ?? 'null'}</td>
                      <td>{item?.gender ?? 'null'}</td>
                      <td>{item?.age ?? 'null'}</td>
                      <td>{item?.phone_no ?? 'null'}</td>
                      <td>{item?.religion ?? 'null'}</td>
                      <td>{item?.caste ?? 'null'}</td>
                      <td>{item?.profession ?? 'null'}</td>
                      <td>{item?.education ?? 'null'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* begin::Body */}
        </div>
      </div>
    </div>
  )
}

export default AssemblyReportTable

/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from '../../../fetchApi/Api'
// import axios from 'axios'
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
import {auth} from '../firebase'
import {signInWithPhoneNumber, RecaptchaVerifier} from 'firebase/auth'
import {showToast} from '../../../../customModel'
import {isPhoneExistOrNotService} from '../../../services/Services'

export function Login() {
  const [loading, setLoading] = useState(false)
  // const {, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [OTP, setOTP] = useState('')
  const [expandForm, setExpandForm] = useState(false)
  const {setCurrentUser} = useAuth()
  const [isloading, setisLoading] = useState(false)
  const [sendOTP, setSendOTP] = useState(false)

  const gererateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (Response) => {},
      },
      auth
    )

    /**
     *
     * (`+91${phone}`)
     */
  }

  const requestOTP = (e) => {
    e.preventDefault()
    isPhoneExistOrNotService(phone)
      .then((Response) => {
        // console.log(Response.data)
        localStorage.setItem('userLogins', JSON.stringify(Response.data.data))
        // sessionStorage.setItem('userSession', JSON.stringify(Response.data.data.PHONE))
        // console.log(Response.data.data.PHONE)
        setSendOTP(true)

        gererateRecaptcha()
        let appVerifier = window.recaptchaVerifier
        console.log('appverifer',appVerifier);
        signInWithPhoneNumber(auth, `+91${phone}`, appVerifier)
          .then((confirmationResult) => {
            console.log('otp sent2');
            window.confirmationResult = confirmationResult
            setExpandForm(true)
          })
          .catch((error) => {
            console.log(error)
            showToast.error(error.message)
            setSendOTP(false)
          })
      })
      .catch((error) => showToast.error('Phone Number does not exist'))
    // }
  }

  const verifyOTP = (e) => {
    let otp = e.target.value
    setOTP(otp)

    if (otp.length === 6) {
      setisLoading(true)
      let confirmationResult = window.confirmationResult
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // const user = result.user
          const userDetails = localStorage.getItem('User-Details')
          const user = {
            ...result.user,
            ...JSON.parse(userDetails),
          }
          console.log(user)

          setCurrentUser(user)
          sessionStorage.setItem('userSession', JSON.stringify(user))
          // console.log(user)
          navigate('/dashboard')
        })
        .catch((error) => {
          setisLoading(false)
          console.log(error.message)
          showToast.error(error.message)
        })
    }
  }

  return (
    <>
      {!isloading && (
        <div className='p-md-20 p-8 shadow-lg p-3 mb-5 bg-body rounded'>
          <form onSubmit={requestOTP}>
            <div className='text-left mb-11'>
              <h1 className='fw-bolder mb-3' style={{color: '#0a8202'}}>
                Log In
              </h1>
              <div className='text-gray-500 fw-semibold fs-6'>To your PoliTrack account</div>
            </div>

            <p className='text-gray-500 fw-bold fs-7'>Please login with your phone number</p>
            <div className='form-outline mb-4'>
              {/* <label htmlFor='phonenumberinput' className='form-label fw-bolder text-dark fs-6'>
              Phone number
            </label> */}

              <input
                type='tel'
                className='form-control bg-light'
                id='phoneNumberInput'
                aria-describedby='emailHelp'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {expandForm && (
              <div className='form-outline mb-4'>
                <label htmlFor='otpInput' className='form-label'>
                  OTP
                </label>
                <input
                  type='phone'
                  className='form-control'
                  id='otpInput'
                  aria-describedby='emailHelp'
                  value={OTP}
                  onChange={verifyOTP}
                />
                <div id='otpHelp' className='form-text'>
                  Please enter the One Time Pin sent to your phone
                </div>
              </div>
            )}
            {!expandForm && !sendOTP && (
              <div className=' pt- mb-5 pb-1 w-100'>
                <button
                  type='submit'
                  className='btn btn-dark text-white mb-3 w-100'
                  style={{background: '#0a8202'}}
                >
                  Request OTP
                </button>
              </div>
              // btn btn-primary mx-auto fs-small
            )}

            {!expandForm && sendOTP && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
            <div id='recaptcha-container'></div>
          </form>
        </div>
      )}
      {isloading && (
        <h2 className='text-center mb-11 text-dark fw-bolder'>
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          <br />
          Please wait.
        </h2>
      )}
    </>
  )
}

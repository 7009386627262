const EndPoints = {
  isPhoneExistOrNot: '/users',
  dashboardData: '/reports/dashboard',
  getBoothVolCountbyAcNo: '/BV/count',
  createBoothCountbyAcNo: '/booth/report',
  getVoluteerCount: '/voters/count',
  sendWebPushNotification: 'https://onesignal.com/api/v1/notifications',
  getAllNotificationList: 'https://onesignal.com/api/v1/notifications',
  getReportsData: '/reports/voterlist',
  getQandAforfilter: '/getQuestionAns',
  getBoothVolDashboardData: '/reports/dashboard/BV',
  getActivityUserList: '/admin/getUsers',
  getActivityUserListCount: '/voters/count',
  getUserMap: '/showOnMap'
}

export default EndPoints

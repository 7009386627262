
import axios from '../../../../../app/fetchApi/Api'
import React, {useEffect, useState, useMemo} from 'react'
import {CardsWidget17} from './CardsWidget17'
import {CardsWidget20} from './CardsWidget20'
import {CardsWidget21} from './CardsWidget21'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import allPStations from '../../../../../JSON/pstation.json'
import allBooths from '../../../../../JSON/booth.json'
import Moment from 'moment'

const UserCard = () => {
  const [boothData, setBoothData] = useState([])
  var location = useLocation()
  // console.log('uselocation.data-----', location.state)
  var user = location.state
  const [totalToBeCreated, setData1] = useState(null)
  const [remainng, setRemainng] = useState(null)
  const [remainning1, setRemaining1] = useState([])
  const [reportCount, setReportCount] = useState('')

  const [touchData, setTouchData] = useState([])
  const [untouchData, setUntouch] = useState([])
  const [completedData, setCompleted] = useState([])
  const [booth, setBooth] = useState([])
  const [totalCreatedByMe, setTotalCreatedByMe] = useState('')
  
  const l = user.ACCESS.length
  const renderPollingStations = (item, index) => {
    return <PollingStationCard child={item} childIndex={index} />
  }


  var startDate = Moment(new Date()).startOf('week').format('YYYY-MM-DD')
  var endDate = Moment(new Date()).endOf('week').format('YYYY-MM-DD')

  useEffect(() => {
    axios
      .get(
        `/get/allBVbyCM?userId=23896&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => setReportCount(response.data.count))
      .catch((erorr) => console.log('error'))

    const pStationsThroughId = user.ACCESS.filter((item) => item?.AC_NO == user?.AC_NO)
    const psBuildingIds = pStationsThroughId.reduce((allItem, currentItem) => {
      const psBData = currentItem.PSBUILDING.map((cItem) => cItem.PSBUILDING_ID)
      return [...allItem, ...psBData]
    }, [])

    const filteredBooths = allBooths.filter(
      (item) => item.AC_NO == user?.AC_NO && psBuildingIds.includes(item.PS_BUILDINGS_ID)
    )

    setBoothData(filteredBooths)

    const partNosString = filteredBooths.map((item) => item.PART_NO)
    setData1(partNosString.length)
    var body = {
      part_no: partNosString,
    }

    axios
      .post(`/booth/report?CM=${user.USER_ID}`, body)
      .then((result) => {
        axios
          .get(`/get/allBVbyCM?userId=${user.USER_ID}`)
          .then((t) => {
            // console.log('t---------', t)
            setTotalCreatedByMe(t.data.count)
          })
        setRemaining1(result.data.data.length)
        const resultPartNos = result.data.data?.map((item) => item?.part_no)
        result.data.data.map((item) => {
          const singleBoothData = filteredBooths.find((boothItem) => {
            return boothItem?.PART_NO === item?.part_no
          })
          if (item.report === 'Completed') {
            setCompleted((oldArray) => [...oldArray, singleBoothData])
          } else if (item.report === 'Touched') {
            setTouchData((oldArray) => [...oldArray, singleBoothData])
          }
        })
        const untouchDt = filteredBooths.filter(
          (boothItem) => !resultPartNos.includes(boothItem.PART_NO)
        )
        setUntouch(untouchDt)
      })
  }, [])

  return (
    <>
      <div className=''>
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
            color: '#0a8202 ',
            fontSize: '20px',
          }}
        >
          {user.NAME}
        </h2>
        <div className={`card shadow  w-md-75 mx-auto`}>
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-auto'>Polling Stataion </th>

                    {/* <th className='w-auto'>Actions</th> */}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    {/* <td className='d-flex' >
                      <p
                        className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                        
                      >
                        {item.NAME}
                      </p>
                    </td> */}
                    <td>{user.ACCESS.map(renderPollingStations)}</td>
                    {/* <td>
                 
                </td> */}
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
        <div className='row g-10 g-xl-10 mb-5 mb-xl-10 mt-8 w-md-75 mx-auto'>
          {/* begin::Col */}
          <div className=' w-md-50 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <CardsWidget17
                AC_NO = {user?.AC_NO}
              user_Id={user?.USER_ID}
              className='h-md-100 mb-5 mb-xl-10'
              touchData={touchData}
              untouchData={untouchData}
              completedData={completedData}
              type='user'
              totalCount={totalToBeCreated}
              booth={booth}
              boothData={boothData}
            />
          </div>
          <div className='w-md-50 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <CardsWidget20
              type='user'
              totalToBeCreated={totalToBeCreated}
              totalCreatedByMe={totalCreatedByMe}
              className='h-md-100 mb-5 mb-xl-10 '
              description='Active Projects'
              color='white'
              // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            />
          </div>
          <div className='w-md-50 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <CardsWidget21
              type='user'
              totalToBeCreated={totalToBeCreated}
              reportCount={reportCount}
              className='h-md-70 '
              description='Active Projects'
              color='white'
              // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default UserCard

const PollingStationCard = ({child, childIndex}) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const navigate = useNavigate()

  const currentData = useMemo(
    () => (!isReadMore ? child.PSBUILDING.slice(0, 2) : child.PSBUILDING),
    [isReadMore, child]
  )
  return (
    <div>
      {childIndex > 0 && <hr />}
      <p
        className='text-dark fw-bold d-block mb-1 fs-6'
        style={{textAlign: 'left'}}
        // onClick={() => navigate('/user-card', {state: child})}
      >
        Assembly: {child.AC_NO} - {child.ASSEMBLY_NAME_EN} | {child.ASSEMBLY_NAME_HINDI}
      </p>
      <hr />
      {currentData.map((grandChild, grandChildIndex) => (
        <>
          <p
            style={{textAlign: 'left'}}
          >{`${grandChild.PSBUILDING_ID} - ${grandChild.PSBUILDING_NAME_EN} | ${grandChild.PSBUILDING_NAME_V1}`}</p>
          {grandChildIndex !== currentData?.length - 1 && <hr />}
        </>
      ))}
      {child.PSBUILDING?.length > 2 && (
        <strong
          style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer', color: '#0a8202 '}}
          onClick={() => setIsReadMore(!isReadMore)}
        >
          {isReadMore ? 'Read Less...' : `Read More(${child.PSBUILDING.length - 2})`}
        </strong>
      )}
    </div>
  )
}
export {UserCard}

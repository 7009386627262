import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import PoliTrackLogo from '../../../../../app/Icons/PoliTrackLogo.jpeg'
export function MenuInner() {
  const intl = useIntl()
  const UserLogins = JSON.parse(localStorage.getItem('userLogins'))
  // console.log('User Role Id', User_ID.ROLE.ROLE_ID )
  const User_Id = UserLogins.ROLE.ROLE_ID
  return (
    <>
      {/* Admin */}
      {User_Id === 3 && (
        <div className='d-sm-flex gap-5 align-items-center'>
          <img src={PoliTrackLogo} style={{width: '50px', height: '50px', marginRight: 20}} />
          <MenuItem title='Dashboard' to='/dashboard' />
          <MenuItem title='Reports' to='/assembly-report' />
          <MenuItem title='Send Notification' to='send-notification' />
        </div>
      )}
      {/* Volunteer */}
      {User_Id === 2 && (
        <div className='d-sm-flex gap-5 align-items-center'>
          <img src={PoliTrackLogo} style={{width: '50px', height: '50px', marginRight: 20}} />
          <MenuItem title='Dashboard' to='/dashboard' />
        </div>
      )}
      {/* Menber */}
      {User_Id === 1 && (
        <div className='d-sm-flex gap-5 align-items-center'>
          <img src={PoliTrackLogo} style={{width: '50px', height: '50px', marginRight: 20}} />
          <MenuItem title='Dashboard' to='/dashboard' />
          <MenuItem title='Reports' to='/booth-volunteers-table' />
          {/* <MenuItem title='Users' to='' /> */}
        </div>
      )}
      {/* Dist Incharge */}
      {User_Id === 5 && (
        <div className='d-sm-flex gap-5 align-items-center'>
          <img src={PoliTrackLogo} style={{width: '50px', height: '50px', marginRight: 20}} />
          <MenuItem title='Dashboard' to='/dashboard' />
        </div>
      )}
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */

// import axios from 'axios'
import axios from '../../../../app/fetchApi/Api'
import React, {useEffect, useMemo, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {
  getActivityUserListCountService,
  getActivityUserListService,
} from '../../../../app/services/Services'
import {BsFillTelephoneFill} from 'react-icons/bs'

const ActivityUserList = ({className}) => {
  const [data, setData] = useState([])
  const [total, setTotal] = useState([])

  //Pagination
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [totalCountfromAPI, setTotalCountfromAPI] = useState('')
  const [pgNo, setPgNo] = useState(1)
  //Loading
  const [isLoading, setIsLoading] = useState(true)

  //Locatiojn
  const navigate = useNavigate()
  const Location = useLocation()
  const user = Location.state

  let UserLogins = JSON.parse(localStorage.getItem('userLogins'))

  useEffect(() => {
    const data = {
      userId: UserLogins?.USER_ID,
      offset: offset,
      limit: 10,
      ROLE_ID: 2,
      AC_NO: user?.AC_NO,
    }
    setTotal([])
    getActivityUserListService(data).then((Response) => {
      setData(Response.data.data)
      // console.log('Response data', Response.data.data)
      setTotalCountfromAPI(Response.data.count)
      Response.data.data.map((i) => {
        const getCountData = {userId: i.USER_ID, AC_NO: 147}
        getActivityUserListCountService(getCountData).then((Response) => {
          var c = {data: Response.data, USER_ID: i.USER_ID}
          setTotal((oldArray) => [...oldArray, c])
          // console.log('next', offset)
          setIsLoading(false)
        })
      })
    })
    // axios
    //   .get(
    //     `admin/getUsers?userId=${UserLogins?.USER_ID}&limit=10&offset=${offset}&ROLE_ID=2&AC_NO=${user?.AC_NO}`
    //   )
    //   .then((Response) => {
    //     console.log(Response.data.data)
    //     Response.data.data.map((i) =>
    //       axios
    //         .get(`voters/count?AC_NO=147&userId=${i.USER_ID}`)
    //         .then((Response) => console.log('Response count',Response.data, 'for the user id', i.USER_ID))
    //     )
    //   })
  }, [offset, page])

  const totalPages = Math.ceil(totalCountfromAPI / 10)

  const renderPollingStations = (item, index) => {
    return <PollingStationCard child={item} childIndex={index} />
  }
  // console.log('aman----------------------------------------', total)
  return (
    <div className='container mt-10'>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          color: '#0a8202 ',
          fontSize: '20px',
        }}
      >
        User List
      </h2>

      <div className={`card ${className} shadow  w-100 mx-auto mb-20`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-auto'>S.no</th>
                  <th className='w-auto'>Name</th>
                  <th className='w-sm-auto'>Polling Stataion</th>
                  <th className='w-auto'>Phone no.</th>

                  {/* <th className='w-auto'>Actions</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              {isLoading && (
                <div>
                  <span className='spinner-grow text-gray-900' role='status'></span>
                </div>
              )}
              {!isLoading && (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={item.USER_ID}>
                      <td className='text-dark fw-bold'>{page * 10 + index + 1}</td>
                      <td
                        className='d-flex-col align-items-start'
                        // onClick={() =>
                        //   navigate('/user-card', {state: {...item, AC_NO: user.AC_NO}})
                        // }
                      >
                        <p className='text-dark fw-bold d-block mb-1 fs-6'>{item.NAME}</p>

                        {total.map((i) => (
                          <>
                            {item.USER_ID == i.USER_ID ? (
                              <p
                                style={{
                                  background: 'green',
                                  borderRadius: '60px',
                                  color: 'white',
                                  width: '35px',
                                  height: '25px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {i.data.count}
                              </p>
                            ) : (
                              <b></b>
                            )}
                          </>
                        ))}
                      </td>
                      <td>{item.ACCESS.map(renderPollingStations)}</td>
                      <td>
                        <a
                          href={`tel:${item.PHONE}`}
                          className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                        >
                          <span className='p-3' style={{color: '#0a8202'}}>
                            <BsFillTelephoneFill />
                          </span>
                          {item.PHONE}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        <div>
          <div className='d-flex justify-content-end gap-10 me-10 mb-10 align-items-center'>
            {offset !== 0  && (
              <div
                className='btn btn-dark'
                onClick={() => {
                  setOffset(offset - 10)
                  setPage(page - 1)
                  setPgNo(pgNo -1)
                  setIsLoading(true)
                }}
              >
                Previous
              </div>
            )}
            <div className='fw-bold text-muted'>
              {' '}
              {pgNo} /{totalPages}
            </div>
            {totalPages > pgNo && (
              <div
                className='btn btn-success'
                onClick={() => {
                  setOffset(offset + 10)
                  setPage(page + 1)
                  setIsLoading(true)
                  setPgNo(pgNo + 1)
                }}
              >
                Next
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const PollingStationCard = ({child, childIndex}) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const navigate = useNavigate()

  const currentData = useMemo(
    () => (!isReadMore ? child.PSBUILDING.slice(0, 2) : child.PSBUILDING),
    [isReadMore, child]
  )
  return (
    <div>
      {childIndex > 0 && <hr />}
      <p
        className='text-dark fw-bold  d-block mb-1 fs-6'
        style={{textAlign: 'left'}}
        // onClick={() => navigate('/user-card', {state: child})}
      >
        Assembly: {child.AC_NO} - {child.ASSEMBLY_NAME_EN} | {child.ASSEMBLY_NAME_HINDI}
      </p>
      <hr />
      {currentData.map((grandChild, grandChildIndex) => (
        <>
          <p
            style={{textAlign: 'left'}}
          >{`${grandChild.PSBUILDING_ID} - ${grandChild.PSBUILDING_NAME_EN} | ${grandChild.PSBUILDING_NAME_V1}`}</p>
          {grandChildIndex !== currentData?.length - 1 && <hr />}
        </>
      ))}
      {child.PSBUILDING?.length > 2 && (
        <strong
          style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer', color: '#0a8202 '}}
          onClick={() => setIsReadMore(!isReadMore)}
        >
          {isReadMore ? 'Read Less...' : `Read More(${child.PSBUILDING.length - 2})`}
        </strong>
      )}
    </div>
  )
}
export {ActivityUserList}

import React from 'react'
import {UsersTable} from '../../../../_metronic/partials/widgets'

const ListofUsers = () => {
  return (
    <>
    <UsersTable/>
    </>
  )

}

export default ListofUsers

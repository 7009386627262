import React from 'react'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
const BoothStatusCard = ({
  type,
  className,
  totalToBeCreated,
  totalCreatedByMe,
  color,
  AC_NO,
  img,
  user_Id,
  boothActive,
  boothInactive,
  totalCount,
  completedData,
  boothData
}) => {
  let commiteeMemberRatio = 0
  if (totalCount > 0) {
    commiteeMemberRatio = (boothActive.length * 100) / totalCount
  }

  var location = useLocation()

  const handle = () => {
    if (type === 'user') {
      var user1 = location.state
      navigate('/user-booth-details', {
        state: {
          user1,
          boothActive,
          totalCount,
          boothData,
          completedData,
          boothInactive,
          user_Id,
          AC_NO,
        },
      })
    } else {
      navigate('/booth-status-page', {state: {AC_NO}})
      // console.log('Booth in area', AC_NO)
    }
  }

  var navigate = useNavigate()

  const handleUserListBooth = (type) => {
    var user1 = location.state
    navigate('/user-booth-details', {state: {user1, boothActive, type, boothData, totalCount, completedData, boothInactive, user_Id,AC_NO }})
  }
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end shadow ${className}`}
      style={{
        backgroundColor: color,
        height: '300px',
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='d-flex flex-column justify-content-between   text-black fs-20 w-100 mt-5 mb-2'>
          <div className='text-black fw-bold fs-5 text-gray-800' style={{fontWeight: 'bold'}}>
            Booth Status
          </div>
          <div
            style={{width: '90%', height: '18px', backgroundColor: '#F64E60'}}
            className='mx-3 w-100  rounded-pill my-15'
          >
            <div
              className='rounded-pill'
              role='progressbar'
              style={{width: `${commiteeMemberRatio}%`, height: '18px', backgroundColor: '#00A3FF'}}
              aria-valuenow={totalToBeCreated * 10 - totalCreatedByMe}
              aria-valuemin={0}
              aria-valuemax={totalToBeCreated * 10}
            ></div>
            <div className='d-flex justify-content-between fw-bold text-gray-600'>
              <p>0</p> <p> Total Booths {totalCount}</p>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex fw-bold text-gray-600'>
              <div className='d-flex flex-column gap-2'>
                <div
                  className='bullet  rounded-pill-2 me-1 py-2 px-4'
                  style={{backgroundColor: '#00A3FF'}}
                ></div>
                <div className='text-center'>{boothActive.length}</div>
              </div>
              Booth Active
            </div>
            <div className='d-flex fw-bold text-gray-600'>
              <div className='d-flex flex-column gap-2'>
                <div
                  className='bullet  rounded-pill-2 me-1 py-2 px-4'
                  style={{backgroundColor: '#F64E60'}}
                ></div>
                <div className='text-center cursor:pointer'>
                  {totalCount - (boothActive.length + completedData.length)}
                </div>
              </div>
              Booths Inactive
            </div>
            <div
              className='p-2 rounded cursor-pointer fw-bold'
              onClick={handle}
              style={{backgroundColor: 'rgba(54, 153, 255, 0.1)', color: '#3699FF'}}
            >
              Booth list
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {BoothStatusCard}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
const TablesWidget13 = ({className, touch}) => {
  var navigate = useNavigate()
  const handle = (i) => {
    // console.log('i--------------', i)
    navigate('/booth-details', {state: i})
  }
  return (
    <div className={`card ${className} shadow`}>
      
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                 
                </th>
                
                {/* <th className='min-w-140px'>AC_NO</th> */}
                <th className='min-w-120px'>PART NO</th>
                <th className='min-w-120px'>PART NAME EN</th>
                <th className='min-w-120px'>PART NAME V1</th>
                {/* <th className='min-w-120px'>PS_BUILDINGS_ID</th> */}
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {touch.map((i, index) => (
                <tr className='text-dark fw-bold bg-hover-primary fs-6' onClick={() => handle(i)}>
                  <td>
                    {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      56037-XDER
                    </a> */}
                  </td>
                  <td>
                    <a className='text-dark fw-bold  fs-6'>{index + 1}</a>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{i.PART_NO}</td>

                  {/* <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {i.AC_NO}
                    </a>
                  </td> */}
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {i.PART_NAME_EN}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {i.PART_NAME_V1}
                    </a>
                  </td>
                  {i.totalBV != null ? (
                    <td>
                      <p
                        style={{
                          background: 'green',
                          borderRadius: '60px',
                          color: 'white',
                          width: '35px',
                          height: '25px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {i.totalBV}
                      </p>
                    </td>
                  ) : (
                    <b></b>
                  )}
                  {/* <td className='text-dark fw-bold text-hover-primary fs-6'>{i.PART_NO}</td> */}
                  {/* <td>
                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                      {i.PS_BUILDINGS_ID}
                    </td>
                    {/* <span className='badge badge-light-success'>Approved</span> */}
                  {/* </td> */}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget13}

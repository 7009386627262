/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef} from 'react'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import DoughnutChart from '../../../../../app/customComponents/doughnutChart/DoughnutChart'
const CardsWidget17 = ({
  className,
  chartSize = 100,
  chartLine = 15,
  chartRotate = 145,
  touchData,
  untouchData,
  completedData,
  totalCount,
  booth,
  type,
  user_Id,
  AC_NO,
  boothData
}) => {
  const chartRef = useRef()
  const {mode} = useThemeMode()
  var location = useLocation()
  // console.log('amanuselocation----------------', location.state)
  var navigate = useNavigate()
  useEffect(() => {
    refreshChart()
  }, [mode, touchData, totalCount, booth, completedData])
  
  const handle = () => {
    if(type === 'user') {
        var user1 = location.state
        navigate('/user-booth-details', {state: {user1, touchData, totalCount, boothData, completedData, untouchData, user_Id, AC_NO}})
    }
    else {
        navigate('/booth-in-area',{state: {AC_NO}})
        console.log('Booth in area',AC_NO);
    }
  }

  const handleUserListBooth = (type) => {
    var user1 = location.state
    navigate('/user-booth-details', {state: {user1, touchData, type, boothData, totalCount, completedData, untouchData, user_Id,AC_NO }})
  }

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    setTimeout(() => {
      initChart({
            chartSize,
            chartLine,
            chartRotate,
            totalCount: totalCount,
            touchCount: touchData.length,
            completedCount: completedData.length,
        });
    }, 1000)
  }

  const data = [
    {label: 'Touched', color: '#00A3FF', value: touchData?.length},
    {label: 'Untouched', color: '#F64E60', value: (totalCount - (touchData?.length + completedData?.length))},
    {label: 'Completed', color: '#50CD89', value: completedData?.length},
  ];

  console.log('aman0-----------', touchData, totalCount - touchData.length - completedData.length, completedData)
  return (
    <div className={`card card-flush shadow ${className}`}>
      <div className='card-header pt-5'>
        {/* <div className='card-title d-flex flex-column'>
          <span className='text-black-400 pt-1 fw-semibold fs-6'>
            {' '}
            <div style={{fontWeight: 'bold'}}>Tasks/Booths Status</div>
          </span>
        </div> */}
        <div className='d-flex justify-content-between fw-bold text-black fs-20 opacity-75 w-100 mt-5 mb-2'>
          <span className='text-black fw-bold fs-6' style={{fontWeight: 'bold'}}>
            {' '}
            <b>Tasks/Booths Status</b>
          </span>{' '}
          <span style={{display: 'flex'}}>
            {type === 'user' && (
              //Aman Soni Work starts
              <div>
                <button
                  onClick={() => handleUserListBooth('allBooths')}
                  className='btn-lg fs-md-8 p-2'
                  style={{
                    background: 'rgba(54, 153, 255, 0.1)',
                    border: ' 1px solid rgba(54, 153, 255, 0.1)',
                    color: '#3699FF',
                    // fontSize: '12px',
                    // padding: '6px',
                    borderRadius: '7px',
                    marginRight: 10,
                  }}
                >
                  Booths wise report
                </button>
              </div>
            )} 
              <button
                onClick={handle}
                className='btn-lg fs-md-8 p-2 fs-9'
                style={{
                  background: 'rgba(54, 153, 255, 0.1)',
                  border: ' 1px solid rgba(54, 153, 255, 0.1)',
                  color: '#3699FF',
                  // fontSize: '12px',
                  // padding: '6px',
                  borderRadius: '7px',
                }}
              >
                Booths in Area
              </button>
          </span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
        <div style={{width: '150px'}}>
            <DoughnutChart data={data} />
          </div>
        </div>
        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div
              className='bullet  rounded-2  me-3'
              style={{background: '#00A3FF', width: '19px', height: '6px'}}
            ></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Booth Touched</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{touchData.length}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div
              className='bullet w-19px h-6px rounded-2 me-3'
              style={{background: '#F64E60', width: '19px', height: '6px'}}
            ></div>
            <>
                <div className='text-gray-500 flex-grow-1 me-4'>Booth Untouched</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{totalCount - (touchData.length + completedData.length)}</div>
            </>
        </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div
              className='bullet rounded-2 me-3'
              style={{backgroundColor: '#50CD89', width: '19px', height: '6px'}}
            ></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Booth Completed</div>
            <div className=' fw-bolder text-gray-700 text-xxl-end'>{completedData.length}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = ({
  chartSize,
  chartLine,
  chartRotate,
  totalCount,
  touchCount,
  completedCount,
}) => {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    // G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color, lineWidth, percent) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'butt' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  drawCircle('#F64E60', options.lineWidth, 1)
  if(touchCount > 0) drawCircle('#00A3FF', options.lineWidth, touchCount / totalCount)
  if(completedCount > 0) drawCircle('#50CD89', options.lineWidth, completedCount / totalCount)
}

export {CardsWidget17}

import axios from '../../../fetchApi/Api'
import React, {useEffect, useState} from 'react'
import allBooths from './../../../../JSON/booth.json'
import allPStations from './../../../../JSON/pstation.json'
import {useLocation} from 'react-router-dom'
// import AllBoth from "./../../../../JSON/booth.json"
// import axios from 'axios'
// import {useLocation} from 'react-router-dom'

import {
  TablesWidget1,
  TablesWidget2,
  TablesWidget3,
  TablesWidget4,
  TablesWidget5,
  TablesWidget6,
  TablesWidget7,
  TablesWidget8,
  TablesWidget9,
  TablesWidget10,
  TablesWidget11,
  TablesWidget12,
  TablesWidget13,
} from '../../../../_metronic/partials/widgets'

const BoothInArea = () => {
  const Location = useLocation()
  const user = Location.state

  const [touch, setTouch] = useState([])
  const [untouch, setUntouch] = useState([])
  const [completed, setCompleted] = useState([])
  const [booth, setBooth] = useState([])
  const [booth1, setBooth1] = useState([])

  useEffect(function () {
    const pStationsThroughId = allPStations.filter((item) => item?.AC_NO == user?.AC_NO)
    const psBuildingIds = pStationsThroughId.map((item) => item.PSBUILDING_ID)
    const filteredBooths = allBooths.filter(
      (item) => item.AC_NO == user?.AC_NO && psBuildingIds.includes(item.PS_BUILDINGS_ID)
    )
    const partNosString = filteredBooths.map((item) => item.PART_NO)
    // setData1(partNosString.length)
    setBooth(filteredBooths)
    var body = {
      part_no: partNosString,
    }
    axios
      .post(`/booth/report?AC_NO=${user?.AC_NO}`, body)
      .then((data) => {
        // setBooth(data.data.data)
        console.log('data.data.data', data.data.data)
        var array1 = filteredBooths
        var array2 = data.data.data
        // const cars1IDs = new Set(data.data.data.map(({id}) => id))
        // const combined = [...data.data.data, ...filteredBooths.filter(({id}) => !cars1IDs.has(id))]
        data.data.data.map((i) => {
          array1 = array1.filter((item) => item.PART_NO !== i.part_no)
          // array1.pop(i.part_no)
        })
        setUntouch(array1)
        filteredBooths.map((i) => {
          data.data.data.map((item) => {
            if (item.report === 'Completed') {
              if (item.part_no === i.PART_NO) {
                i.totalBV = item.totalBV
                setCompleted((oldArray) => [...oldArray, i])
                setBooth1((oldArray) => [...oldArray, i])
              }
            } else if (item.report === 'Touched') {
              if (item.part_no === i.PART_NO) {
                i.totalBV = item.totalBV
                setTouch((oldArray) => [...oldArray, i])
                // console.log('Tocheddd', i)
              }
            }
            // else {
            //   setUntouch((oldArray) => [...oldArray, i])
            //   // setUntouch((oldArray) => [...oldArray, item.report])
            //   console.log('unTocheddd', i)
            // }
          })
        })
      })
  }, [])
  const [b1, setB1] = useState(false)
  const [b2, setB2] = useState(false)
  const [b3, setB3] = useState(false)

  return (
    <>
      <div className='container'>
        <h2
          style={{
            display: 'flex',
            color: '#0a8202 ',
            justifyContent: 'center',
            marginBottom: '20px',
            fontSize: '20px',
          }}
        >
          Booth in Area ({booth.length})
        </h2>
        <div class='row flex-center w-100 text-center mb-5'>
          <div class='row flex-center w-50  text-center mb-5'>
            <div class='col mt-2 '>
              {b1 === false ? (
                <button
                  className='btn-lg font-size-sm'
                  style={{
                    background: '#0a8202 ',
                    borderRadius: '7px',
                    border: ' 1px solid #0a8202 ',
                    color: 'white',
                    fontSize: '12px',
                    color: 'white',
                    padding: '6px',
                  }}
                  onClick={() => {
                    setB1(false)
                    setB2(false)
                    setB3(false)
                    setBooth1(completed)
                  }}
                >
                  Completed ( {completed.length})
                </button>
              ) : (
                <button
                  className='  btn-lg font-size-sm'
                  style={{
                    background: 'rgba(54, 153, 255, 0.1)',
                    border: ' 1px solid rgba(54, 153, 255, 0.1)',
                    color: '#3699FF',
                    borderRadius: '7px',
                    fontSize: '12px',
                    color: 'black',
                    padding: '6px',
                  }}
                  onClick={() => {
                    setB1(false)
                    setB2(false)
                    setB3(false)
                    setBooth1(completed)
                  }}
                >
                  Completed ( {completed.length})
                </button>
              )}
            </div>

            <div class='col  mt-2'>
              {b2 === false ? (
                <button
                  onClick={() => {
                    setB1(true)
                    setB2(true)
                    setB3(false)
                    setBooth1(touch)
                  }}
                  className='btn-lg font-size-sm'
                  style={{
                    background: 'rgba(54, 153, 255, 0.1)',
                    border: ' 1px solid rgba(54, 153, 255, 0.1)',
                    color: '#3699FF',
                    fontSize: '12px',
                    borderRadius: '7px',
                    color: 'black',
                    // width: '100px',
                    padding: '6px',
                  }}
                >
                  Touched ({touch.length})
                  {/* <font style={{display: 'flex', justifyContent: 'center', color: '#3699FF'}}>
                {touch.length}
              </font> */}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setB1(true)
                    setB3(false)
                    setBooth1(touch)
                  }}
                  className='btn-lg font-size-sm'
                  style={{
                    background: '#0a8202 ',
                    border: ' 1px solid rgba(54, 153, 255, 0.1)',
                    color: '#3699FF',
                    color: 'white',
                    borderRadius: '7px',
                    fontSize: '12px',
                    // width: '100px',
                    padding: '6px',
                  }}
                >
                  Touched ({touch.length})
                  {/* <font style={{display: 'flex', justifyContent: 'center', color: '#3699FF'}}>
              {touch.length}
            </font> */}
                </button>
              )}
            </div>
            <div class='col  mt-2'>
              {b3 === false ? (
                <button
                  onClick={() => {
                    setB3(true)
                    setB1(true)
                    setB2(false)
                    setBooth1(untouch)
                  }}
                  className='btn-lg font-size-sm'
                  style={{
                    background: 'rgba(54, 153, 255, 0.1)',
                    border: ' 1px solid rgba(54, 153, 255, 0.1)',
                    color: '#3699FF',
                    borderRadius: '7px',
                    color: 'black',
                    fontSize: '12px',
                    // width: '100px',
                    padding: '6px',
                  }}
                >
                  UnTouched( {untouch.length})
                  {/* <font style={{display: 'flex', justifyContent: 'center', color: '#3699FF'}}>
                {untouch.length}
              </font> */}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setB1(true)
                    setB2(false)
                    setB3(true)
                    setBooth1(untouch)
                  }}
                  className=' btn-lg font-size-sm'
                  style={{
                    background: '#0a8202 ',
                    border: ' 1px solid rgba(54, 153, 255, 0.1)',
                    color: '#3699FF',
                    color: 'white',
                    fontSize: '12px',
                    // width: '100px',
                    borderRadius: '7px',
                    padding: '6px',
                  }}
                >
                  UnTouched( {untouch.length})
                  {/* <font style={{display: 'flex', justifyContent: 'center', color: '#3699FF'}}>
              {untouch.length}
            </font> */}
                </button>
              )}
              {/* <button class='w-md-50'>UnTouched</button> */}
            </div>
          </div>
        </div>
        <TablesWidget13 className='mb-5 mb-xl-8' touch={booth1} />
      </div>
    </>
  )
}
export {BoothInArea}

import React from 'react'
export const FilterDropdown = ({
  genderFilter,
  agefromFilter,
  agetoFilter,
  religionFilter,
  casteFilter,
  professionFilter,
  educationFilter,
  setGenderFilter,
  setAgeFromFilter,
  setAgeToFilter,
  setReligionFilter,
  setCasteFilter,
  setProfessionFilter,
  setEducationFilter,
  submitFilter,
  restFilter,
  setSubmitFilter,
 
}) => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
      data-kt-menu='true'
      style={{
        zIndex: '1',
      }}
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div
        className='d-flex-column h-sm-100vh py-10 me-o px-4 justify-content-between w-sm-20'
        style={{
          // width: '20%',

          backgroundColor: '#EBEBEB',

          // background: '#171717',
          // position: 'fixed',
          // display: 'flex',
        }}
      >
        {/* search by filter */}

        <div className='d-flex flex-column gap-5 fw-bold'>
          {/* Gender */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Gender</p>
            <select
              name=''
              id=''
              value={genderFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setGenderFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Male'>Male</option>
              <option value='FeMale'>Female</option>
            </select>
          </div>
          {/* Age Group */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Age Group</p>
            {/* drop down starts */}
            <div className='d-flex w-50 align-items-center gap-1'>
              <select
                name=''
                id=''
                value={agefromFilter}
                className='p-2 bg-gray rounded border-0 w-50 fw-bold text-center cursor-pointer'
                onChange={(e) => {
                  setAgeFromFilter(e.target.value)
                }}
              >
                <option value=''>from</option>
                <option value='18'>18</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='70'>70</option>
              </select>
              -
              <select
                name=''
                id=''
                value={agetoFilter}
                className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
                onChange={(e) => {
                  setAgeToFilter(e.target.value)
                }}
              >
                <option value=''>to</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
                <option value='70'>70</option>
                <option value='200'>70+</option>
              </select>
            </div>
          </div>
          {/* drop down ends */}

          {/* Religion */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Religion</p>
            <select
              name=''
              id=''
              value={religionFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setReligionFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Hindu'>Hindu</option>
              <option value='MUslim'>Muslim</option>
              <option value='Christian'>Christian</option>
              <option value='Jain'>Jain</option>
              <option value='Buddhist'>Buddhist</option>
              <option value='Parsi'>Parsi</option>
              <option value='Sikh'>Sikh</option>
              <option value='Other'>Other</option>
            </select>
          </div>
          {/* Caste */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Caste</p>
            <select
              name=''
              id=''
              value={casteFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setCasteFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Vokkaliga'>Vokkaliga</option>
              <option value='Bramhin'>Bramhin</option>
              <option value='Lingayath'>Lingayath</option>
              <option value='SC'>SC</option>
              <option value='ST'>ST</option>
              <option value='Muslim'>Muslim</option>
              <option value='Christian'>Christian</option>
              <option value='Golla'>Golla</option>
              <option value='Kuruba'>Kuruba</option>
              <option value='Savitha Samaja'>Savitha Samaja</option>
              <option value='Balajiga'>Balajiga</option>
              <option value='Jain'>Jain</option>
              <option value='Marwadi'>Marwadi</option>
              <option value='Others'>Others</option>
            </select>
          </div>
          {/* Profession */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Profession</p>
            <select
              name=''
              id=''
              value={professionFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setProfessionFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='Farmer'>Farmer</option>
              <option value='Private Employee'>Private Employee</option>
              <option value='Self-employee (Business)'>Self-employee (Business)</option>
              <option value='Govt Employee'>Govt Employee</option>
            </select>
          </div>
          {/* Education */}
          <div className='d-flex justify-content-between mx-3 align-items-center'>
            <p>Education</p>
            <select
              name=''
              id=''
              value={educationFilter}
              className='p-2 bg-gray rounded border-0 fw-bold w-50 text-center cursor-pointer'
              onChange={(e) => {
                setEducationFilter(e.target.value)
              }}
            >
              <option value=''>Not selected</option>
              <option value='10th or Below'>10th or Below</option>
              <option value='10+2/Intermediate'>10+2/Intermediate</option>
              <option value='Graduate'>Graduate</option>
              <option value='Post Graduate or Above'>Post Graduate or Above</option>
            </select>
          </div>
          {/* button */}
          <div className='d-flex gap-3 justify-content-evenly mx-auto text-center'>
            <button
              className='btn btn-dark text-white py-1 px-8'
              style={{backgroundColor: '#0a8202'}}
              onClick = {() => setSubmitFilter(!submitFilter)}
            >
              Filter
            </button>
            <button
              onClick={restFilter}
              className='btn btn-dark text-white py-1 px-8'
              style={{backgroundColor: '#0a8202'}}
            >
              Rest filter
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

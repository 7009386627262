import moment from "moment";

// Get Valid Date
export const getValidDate = (date = null, format, getDateObject = false) => {
    let validDate = date
    if (getDateObject) return new Date(getValidDate(validDate, 'YYYY/MM/DD'))
    // if (validDate?.[1] && deductMonth) validDate[1] -= 1
    if (moment(validDate).isValid()) {
        if (getDateObject) return validDate
        return moment(validDate).format(format ?? 'YYYY/MM/DD')
    }
    return null
}
import React from 'react'
import {useNavigate} from 'react-router-dom'
const CardsWidget20 = ({
  type,
  className,
  totalToBeCreated,
  totalCreatedByMe,
  color,
  AC_NO,
  img,
}) => {
  let commiteeMemberRatio = 0
  if (totalToBeCreated > 0) {
    commiteeMemberRatio = (totalCreatedByMe * 100) / (totalToBeCreated * 10)
  }

  var navigate = useNavigate()
  const handle = () => {
    navigate('/user-table', {state: {AC_NO}})
  }
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end shadow ${className}`}
      style={{
        backgroundColor: color,
        height: '300px',
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='d-flex justify-content-between fw-bold text-black fs-20 opacity-75 w-100 mt-5 mb-2'>
          <span className='text-black fw-bold fs-6' style={{fontWeight: 'bold'}}>
            {' '}
            <b>Progress/Booth Volunteers</b>
          </span>{' '}
          <span>
            {/* TODO: Implemented In Future */}
            {type !== 'user' && totalToBeCreated > 0 && <button
              onClick={() => handle()}
              className='btn-lg fs-md-8'
              style={{
                background: 'rgba(54, 153, 255, 0.1)',
                border: ' 1px solid rgba(54, 153, 255, 0.1)',
                color: '#3699FF',
                fontSize: '12px',
                padding: '6px',
                borderRadius: '7px',
              }}
            >
              List of Users
            </button>}
          </span>
        </div>
      </div>
      <div className='card-body d-flex align-items-start pt-0 h-100 mt-md-0 mt-10'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div style={{width: '90%', height: '18px'}} className='mx-3 w-100 bg-gray-200  rounded'>
            <div
              className='bg-danger rounded'
              role='progressbar'
              style={{width: `${commiteeMemberRatio}%`, height: '18px', background: 'red'}}
              aria-valuenow={totalToBeCreated * 10 - totalCreatedByMe}
              aria-valuemin={0}
              aria-valuemax={totalToBeCreated * 10}
            ></div>
            <div className='d-flex justify-content-between fw-bold fs-20 text-gray opacity-75 w-100 mt-1 mb-2'>
              <span>0</span>
              <span>
                <span>Total to be created &nbsp;{totalToBeCreated * 10}</span>
              </span>
            </div>
            <div style={{marginTop: '0px'}} className='d-sm-flex flex-row-fluid'>
              <div
                style={{marginTop: '7px', width: '19px', height: '6px'}}
                className='bullet  rounded-2 bg-danger me-1'
              ></div>
              <div style={{color: 'grey', fontSize: '13px'}}>
                Total created by all commitee members {totalCreatedByMe}
              </div>
            </div>
            {type === 'user' ? (
              <div className='d-flex flex-row-fluid mt-5'>
                <div
                  style={{marginTop: '7px', width: '19px', height: '6px'}}
                  className='bullet  rounded-2 bg-danger me-1'
                ></div>
                <div style={{color: 'grey', fontSize: '13px', padding: '0px'}}>
                  Total committe members by me {totalCreatedByMe}
                </div>
              </div>
            ) : null}
            <div style={{marginTop: '10px'}} className='d-flex flex-row-fluid'>
              <div
                style={{marginTop: '7px', background: '#E4E6EF', width: '19px', height: '6px'}}
                className='bullet rounded-2  me-1'
              ></div>
              <div style={{color: 'grey'}}>
                Remaining{' '}
                <b style={{marginLeft: '50px'}}>{totalToBeCreated * 10 - totalCreatedByMe}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {CardsWidget20}

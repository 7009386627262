import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import power from '../../../../app/Icons/power.png'
import {BroadcastChannel} from 'broadcast-channel'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const logoutChannel = new BroadcastChannel('logout')

export const logout = () => {
  logoutChannel.postMessage('Logout')
  sessionStorage.removeItem('userSession')
  localStorage.removeItem('userLogins')
  window.location.replace('auth')
}

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout()
    logoutChannel.close()
  }
}

const Navbar = () => {
  const {config} = useLayout()
  const handle = () => {
    logout()
  }

  const UserLogins = JSON.parse(localStorage.getItem('userLogins'))
  // console.log('First letter', UserLogins.NAME);
  const name = UserLogins.NAME
  const fLetter = name.split(' ')[0].charAt(0)
  const lLetter = name.split(' ')[1].charAt(0)
  return (
    // <div className='app-navbar flex-shrink-0'>
    <div className='app-navbar d-flex gap-sm-0 gap-5'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-0 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}

      <div className='d-flex'>
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            style={{backgroundColor: 'rgba(32, 212, 137, 0.5)'}}
            className={clsx('cursor-pointer symbol p-2 text-white fw-bold fs-5')}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            {fLetter}
            {lLetter}
          </div>
        </div>
        <div
          onClick={() => handle()}
          style={{cursor: 'pointer'}}
          className={clsx('app-navbar-item text-white mx-sm-10', itemClass)}
        >
          <img src={power} className='mx-5'></img>
          Logout
        </div>
      </div>
    </div>
  )
}

export {Navbar}

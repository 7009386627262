/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
const DevConfig = {
    ENABLE_CONSOLE_LOGS : true
}

const withoutAuthAxios = axios.create({
    baseURL: 'https://3wyivw6okf.execute-api.ap-south-1.amazonaws.com/dev/v1/',
    timeout: 30000,
});

withoutAuthAxios.interceptors.request.use(async (config) => {
    if (DevConfig.ENABLE_CONSOLE_LOGS) console.log(`%c API REQUEST FOR : ${config?.url} `, 'background: #222; color: #FFFF00', config)
    return config;
});

withoutAuthAxios.interceptors.response.use(
    (res) => {
        if (DevConfig.ENABLE_CONSOLE_LOGS) console.log(`%c API SUCCESS RESPONSE FOR : ${res?.config?.url} `, 'background: #222; color: #00FF00', res)
        return res
    },
    async (error) => {
        if (DevConfig.ENABLE_CONSOLE_LOGS) console.log(`%c API ERROR RESPONSE FOR : ${error?.config?.url} `, 'background: #222; color: #FF0000', error?.response ?? error)
        return Promise.reject(error?.response)
    },
);

export default withoutAuthAxios;

/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from '../../../../app/fetchApi/Api'

import React, {useEffect, useMemo, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
// import {showToast} from '../../../../customModel'

const CommitteeMemberTable = ({className}) => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [total, setTotal] = useState([])

  const UserLogins = JSON.parse(localStorage.getItem('userLogins'))
  // console.log(UserLogins.ACCESS[0].PART)
  const UserAssembly = UserLogins.ACCESS[0]

  // useEffect(() => {
  //   // api()
  //   showToast.success('WELCOME BOOTH VOLUNTEERS')
  // }, [])

  // const renderPollingStations = (item, index) => {
  //   return <PollingStationCard child={item} childIndex={index} />
  // }
  return (
    <div className='container'>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          color: '#0a8202 ',
          fontSize: '20px',
        }}
      >
        Welcome committee member
        <span>{}</span>
      </h2>
      <div className={`card ${className} shadow  w-md-75 mx-auto`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  {/* <th className='w-auto'>Name</th> */}
                  <th className='w-auto'>Assigned to me</th>

                  {/* <th className='w-auto'>Actions</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>
                    <p
                      className='text-dark fw-bold  d-block mb-1 fs-6 py-3'
                      style={{textAlign: 'left', cursor: 'pointer'}}
                      // onClick={() => navigate('/user-card', {state: child})}
                    >
                      <span className='fs-4' style={{color: '#0a8202 '}}>
                        {' '}
                        Assembly |{' '}
                      </span>
                      <br />
                      {UserAssembly.AC_NO} - {UserAssembly.ASSEMBLY_NAME_EN} |
                      {UserAssembly.ASSEMBLY_NAME_HINDI}
                    </p>

                    <hr />

                    <p
                      className='text-dark fw-bold d-block mb-1 fs-6 py-3'
                      style={{textAlign: 'left', cursor: 'pointer'}}
                      // onClick={() => navigate('/user-card', {state: child})}
                    >
                      <span className='fs-4' style={{color: '#0a8202 '}}>
                        {' '}
                        Pooling Station |{' '}
                      </span>
                      <br />
                      {UserAssembly.PSBUILDING[0].PSBUILDING_ID} -
                      {UserAssembly.PSBUILDING[0].PSBUILDING_NAME_EN} |
                      {UserAssembly.PSBUILDING[0].PSBUILDING_NAME_V1}
                    </p>
                  </td>

                  {/* <td>
                    <p
                      className='text-dark fw-bold text-hover-success d-block mb-1 fs-6'
                      style={{textAlign: 'left', cursor: 'pointer'}}
                      // onClick={() => navigate('/user-card', {state: child})}
                    >
                      Assembly: {UserAssembly.AC_NO} - {UserAssembly.ASSEMBLY_NAME_EN} |
                      {UserAssembly.ASSEMBLY_NAME_HINDI}
                    </p>
                  </td> */}
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='d-flex justify-content-around w-md-75  mx-auto mt-15'>
        <div className='w-md-25 text-center py-3 bg-success rounded text-white fw-bold'>
          Report by booth
        </div>
        <div className='w-md-25 text-center py-3 bg-primary rounded text-white fw-bold'>
          My reports
        </div>
      </div>
    </div>
  )
}

export {CommitteeMemberTable}

import {memo, useMemo} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {Chart as ChatJS, ArcElement, Tooltip} from 'chart.js'

ChatJS.register(ArcElement, Tooltip)
const options = {
  cutout: 55,
  responsive: true,
}

const DoughnutChart = ({
  data = {
    label: '',
    value: '',
    color: '',
  },
}) => {
  const customData = useMemo(
    () => ({
      labels: data.map((item) => item?.label),
      datasets: [
        {
          data: data.map((item) => item?.value),
          backgroundColor: data.map((item) => item?.color),
          borderColor: data.map((item) => item?.color),
        },
      ],
    }),
    [data]
  )
  // console.log('it runs');
  // console.log('custom data',customData);
  const totalChartCount = data
    .map((item) => item?.value)
    .reduce((partialSum, a) => partialSum + a, 0)
  // console.log('amnnnn', totalChartCount)
  return (
    <div style={{position: 'relative'}}>
      <Doughnut style={{zIndex: -1}} data={customData} options={options} />
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className='text-center'>
          <h2>{totalChartCount}</h2>
          <p className='fs-8 fw-bold text-gray-700'>Total Reviews</p>
        </div>
      </div>
    </div>
  )
}

export default memo(DoughnutChart)
